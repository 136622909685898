import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader2 from "../../sections/utilities/Loader2";
import { getAuthToken } from "../../services/auth-header";
import { getProcessflowProgresses } from "../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import { getDataFromProgressData } from "../../jason-proof-of-concept/wizard/wizard-container";
import { getEstimatedPayoutFromData } from "../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { getUserCompanies } from "../../user-companies/actions/get-user-companies";
import { useMemo } from "react";
import { deleteUserCompany } from "../../user-companies/actions/delete-user-company";
import roleGroupService, { roleGroups } from "../../services/role-group.service";
import { useCompany } from "../hooks/use-company";
import { classNames } from "../../billing/utils";
import {
    BriefcaseIcon,
    EnvelopeIcon,
    PencilSquareIcon,
    PhoneIcon,
    UserIcon,
    UsersIcon,
    DocumentTextIcon,
    DocumentIcon as FilesIcon,
    CalendarIcon,
    CurrencyDollarIcon,
    RectangleGroupIcon,
} from "@heroicons/react/24/solid";
import { BasicInfo } from "../components/basic-info";
import { Application } from "../components/application";
import { Notes } from "../components/notes";
import { useInteractions } from "../../interactions/hooks/use-interactions";
import { useAuth } from "../../auth/use-auth";
import { Calls } from "../components/calls";
import { Messages } from "../components/messages";
import { Members } from "../components/members";
import { getCompanyUsers } from "../actions/get-company-users";
import { updateUserCompany } from "../../user-companies/actions/update-user-company";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";
import { Billing } from "../components/billing";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { useTransactions } from "../../transactions/hooks/use-transactions";
import { useBreakpoint } from "../../hooks/appMedia";
import OptionsDropDown from "../../layout/options-dropdown";
import { RoleGroup } from "../../role-groups/domain/role-group";
import { usePaymentRequests } from "../../payment-requests/hooks/use-payment-requests";
import { Files } from "../components/files";
import { useHedgeFunds } from "../hooks/use-hedge-funds";
import Package from "../components/package";
import { Mandates } from "../components/mandates";
import { useCompanyLocations } from "../../company-locations/hooks/use-company-locations";
import { Repair } from "../components/repair";
import { Phase1 } from "../components/phase1";
import History from "../components/history";
import { hostname } from "os";
import { useCompanyLineItems } from "../../company-line-items/hooks/use-company-line-items";
import { useCreateCompanyLineItem } from "../../company-line-items/hooks/use-create-company-line-item";
import { useLineItems } from "../../line-items/hooks/useLineItems";
import { LineItemsType } from "../../services/line-items.service";
import { useInvoices } from "../../invoices/queries/use-get-invoices";
import { CPA } from "../components/cpa";
import { usePayments } from "../../payments/hooks/use-payments";
import CompanyGroups from "../components/company-groups";

export const CompanyPage = ({ darkMode }: { darkMode?: boolean }) => {
    const { companyId: companyIdParam } = useParams();
    const url = new URL(window.location.href);
    const auth = useAuth();
    const user = auth.expectUser();
    const [theUser] = useRecoilState(userAtom);
    const isSuperUser = theUser?.roleGroups?.find((r: any) => r.id === 4);
    const isTaxAdvocateUser = theUser?.roleGroups?.find((r: any) => r.id === 38);
    const isTaxAdvocateAffiliate = theUser?.roleGroups?.find((r: any) => r.id === 41);
    const isTaxAdvocateAffiliateAgent = theUser?.roleGroups?.find((r: any) => r.id === 42);
    const isCPAUser = theUser?.roleGroups?.find((r: any) => r.id === 8);
    const tab = url.searchParams.get("tab");
    const authToken = getAuthToken();
    if (!companyIdParam) {
        throw new Error("companyId is required");
    }
    const companyId = parseInt(companyIdParam);
    const navigate = useNavigate();

    const companyQuery = useCompany({ authToken, id: companyId });
    const company = companyQuery.data;

    const hedgeFundsQuery = useHedgeFunds({ authToken });
    const hedgeFunds = useMemo(() => hedgeFundsQuery.data || [], [hedgeFundsQuery.data]);

    const transactionsQuery = useTransactions({ authToken, filters: { where: { companyId } } });
    const transactions = useMemo(
        () => (transactionsQuery.data || []).filter((t) => t.companyId === companyId),
        [companyId, transactionsQuery.data],
    );

    const paymentsQuery = usePayments({ authToken, filters: { where: { companyId } } });
    const payments = useMemo(
        () => (paymentsQuery.data || []).filter((t) => t.companyId === companyId),
        [companyId, paymentsQuery.data],
    );

    const invoicesQuery = useInvoices({ authToken, filters: { where: { companyId } } });
    const invoices = useMemo(
        () => (invoicesQuery.data || []).filter((t) => t.companyId === companyId),
        [companyId, invoicesQuery.data],
    );

    const usersQuery = useQuery(["users", "company", companyId], async () => {
        const userCompanies = await getUserCompanies({ authToken, filters: { where: { companyId: companyId } } });
        const users = await getCompanyUsers({
            authToken,
            id: companyId,
            filters: { include: ["companyRoleGroups", "userCompanies"] },
        });
        return { users, userCompanies };
    });
    const users = useMemo(() => usersQuery.data?.users || [], [usersQuery.data?.users]);
    const userCompanies = useMemo(() => usersQuery.data?.userCompanies || [], [usersQuery.data?.userCompanies]);

    const ownerUserId = company?.ownedById;
    const ownerQuery = useUsers({ authToken, filters: { where: { id: ownerUserId } } }, { enabled: !!ownerUserId });
    const owner = ownerQuery.data?.[0];

    const userCompanyAffiliate = users.find((u) => {
        const affiliateUserCompany = userCompanies.find((uc) => uc.roleGroupId === roleGroups.Affiliate);
        return affiliateUserCompany?.ercUserId === u.id;
    });
    const affiliate = userCompanyAffiliate;

    const userCompanyAccountant = users.find((u) => {
        const accountantUserCompany = userCompanies.find((uc) => uc.roleGroupId === roleGroups.Accountant);
        return accountantUserCompany?.ercUserId === u.id;
    });
    const accountant = userCompanyAccountant;

    const userCompanyTaxAttorney = users.find((u) => {
        const taxAttorneyUserCompany = userCompanies.find((uc) => uc.roleGroupId === roleGroups.TaxAttorney);
        return taxAttorneyUserCompany?.ercUserId === u.id;
    });
    const taxAttorney = userCompanyTaxAttorney;

    const userCompanyPayMaster = users.find((u) => {
        const payMasterUserCompany = userCompanies.find((uc) => uc.roleGroupId === roleGroups.Paymaster);
        return payMasterUserCompany?.ercUserId === u.id;
    });
    const payMaster = userCompanyPayMaster;

    const userCompanyTaxAdvocate = users.find((u) => {
        const taxAdvocateUserCompany = userCompanies.find((uc) => uc.roleGroupId === roleGroups.TaxAdvocate);
        return taxAdvocateUserCompany?.ercUserId === u.id;
    });
    const taxAdvocate = userCompanyTaxAdvocate;

    const interactionsQuery = useInteractions({
        authToken,
        filters: { where: { or: [{ interaction_to: ownerUserId }, { companyId }] } },
        enabled: !!ownerUserId,
    });
    const notes = useMemo(() => (interactionsQuery.data || []).filter((i) => i.type === 3), [interactionsQuery.data]);

    const paymentRequestsQuery = usePaymentRequests({ authToken, filters: { where: { companyId } } });
    const paymentRequests = useMemo(() => paymentRequestsQuery.data || [], [paymentRequestsQuery.data]);

    const filteredNotes = useMemo(() => notes, [notes]);

    const companyLocationsQuery = useCompanyLocations({ authToken, filters: { where: { companyId } } });
    const companyLocations = useMemo(() => companyLocationsQuery.data || [], [companyLocationsQuery.data]);

    const calls = useMemo(() => (interactionsQuery.data || []).filter((i) => i.type === 2), [interactionsQuery.data]);
    const messages = useMemo(
        () => (interactionsQuery.data || []).filter((i) => i.type === 1),
        [interactionsQuery.data],
    );

    const progressQuery = useQuery(["billing-page-calcs2", { userId: ownerUserId }, authToken], async () => {
        if (!ownerUserId) {
            return {};
        }
        const group =
            window.location.hostname === "login.ercrepair.com" || window.location.hostname === "localhost" ? 28 : 7;
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { userId: ownerUserId, group },
        });
        const data = getDataFromProgressData(usersProgress?.data as any);
        const estimatedAmount = getEstimatedPayoutFromData({ data });
        return { usersProgress, data, estimatedAmount };
    });
    const progressQueryHistory = useQuery(["billing-page-calcs2", { userId: ownerUserId }, authToken], async () => {
        if (!ownerUserId) {
            return {};
        }
        const group = 28;
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { userId: ownerUserId, group },
        });
        return { usersProgress };
    });

    const removeUserMutation = useMutation({
        mutationFn: async (userId: string) => {
            const [userCompany] = await getUserCompanies({
                authToken,
                filters: { where: { companyId, ercUserId: userId } },
            });
            await deleteUserCompany({ authToken, id: userCompany.id ?? -1 });
        },
    });

    const updateCommission = useMutation({
        mutationFn: async ({ userCompanyId, commission }: { userCompanyId: number; commission: number }) => {
            await updateUserCompany({
                id: userCompanyId,
                data: { commissionPercentage: commission },
                authToken,
            });
        },
    });

    const companyLineItemsQuery = useCompanyLineItems({ authToken, filters: { where: { companyId } } });
    const companyLineItems = useMemo(() => companyLineItemsQuery.data || [], [companyLineItemsQuery.data]);

    const lineItemsQuery = useLineItems();
    const lineItems = useMemo(() => lineItemsQuery.data?.data || [], [lineItemsQuery.data]) as LineItemsType[];

    const { usersProgress, data, estimatedAmount } = progressQuery.data || {};
    const applicationPercentageProgress = Math.round((usersProgress?.data?.percentageComplete || 0) * 100);

    const roleGroupsQuery = useQuery(["companyRoles", { userId: ownerUserId, companyId }], async () => {
        const response = await roleGroupService.getAll();
        const roles = (response?.data || []) as RoleGroup[];
        return roles;
    });
    const theRoleGroups = useMemo(() => roleGroupsQuery.data || [], [roleGroupsQuery.data]);

    const navItems = useMemo(
        () => [
            {
                name: "Basic Information",
                id: "basic",
                icon: UserIcon,
                content: () =>
                    company ? (
                        <BasicInfo
                            company={company}
                            applicationPercentageProgress={applicationPercentageProgress}
                            owner={owner}
                            affiliate={affiliate}
                            payMaster={payMaster}
                            onCompanyUpdated={() => {
                                companyQuery.refetch();
                            }}
                            onOwnerUpdated={() => {
                                ownerQuery.refetch();
                            }}
                            onCompanyUserUpdated={() => {
                                usersQuery.refetch();
                            }}
                            applicationData={data}
                            accountant={accountant}
                            taxAttorney={taxAttorney}
                            companyLocations={companyLocations}
                            onCompanyLocationCreated={() => companyLocationsQuery.refetch()}
                            onCompanyLocationDeleted={() => companyLocationsQuery.refetch()}
                            onCompanyLocationUpdated={() => companyLocationsQuery.refetch()}
                        />
                    ) : null,
            },

            {
                name: "Users",
                id: "users",
                icon: UsersIcon,
                content: () => {
                    return (
                        company && (
                            <Members
                                company={company}
                                companyRoles={theRoleGroups as any[]}
                                userCompanies={userCompanies}
                                onUserAdded={() => {
                                    usersQuery.refetch();
                                }}
                                onUserUpdated={() => {
                                    usersQuery.refetch();
                                }}
                                onRemoveUser={async (userId) => {
                                    await removeUserMutation.mutateAsync(userId);
                                    usersQuery.refetch();
                                }}
                                onUserInvited={() => {
                                    usersQuery.refetch();
                                }}
                                onCommissionUpdated={async (userCompanyId, commission) => {
                                    await updateCommission.mutateAsync({
                                        userCompanyId,
                                        commission: Number(commission),
                                    });
                                }}
                                users={users}
                                darkMode={darkMode}
                            />
                        )
                    );
                },
            },
            ...(isSuperUser && company
                ? [
                      {
                          name: "Repair Application",
                          id: "phase1",
                          icon: BriefcaseIcon,
                          content: () => {
                              return owner ? (
                                  <Phase1
                                      user={owner}
                                      companyId={companyId}
                                      onChange={() => {
                                          progressQuery.refetch();
                                      }}
                                  />
                              ) : null;
                          },
                      },
                      {
                          name: "Grouped Companies",
                          id: "groupedCompanies",
                          icon: RectangleGroupIcon,
                          content: () => {
                              return (
                                  ownerUserId && company && owner && <CompanyGroups company={company} user={owner} />
                              );
                          },
                      },
                      {
                          name: "Package",
                          id: "package",
                          icon: DocumentTextIcon,
                          content: () => {
                              return ownerUserId && company && owner && <Package companyId={companyId} user={owner} />;
                          },
                      },
                      {
                          name: "Mandates",
                          id: "mandates",
                          icon: DocumentTextIcon,
                          content: () => {
                              return (
                                  ownerUserId && company && owner && <Mandates companyId={companyId} owner={owner} />
                              );
                          },
                      },
                      {
                          name: "History",
                          id: "history",
                          icon: CalendarIcon,
                          content: () => {
                              return (
                                  ownerUserId &&
                                  company && (
                                      <History
                                          companyId={companyId}
                                          owner={owner}
                                          progressQuery={progressQueryHistory}
                                      />
                                  )
                              );
                          },
                      },
                      {
                          name: "Billing",
                          id: "billing",
                          icon: BriefcaseIcon,
                          content: () => {
                              return (
                                  data && (
                                      <Billing
                                          applicationData={data}
                                          company={company}
                                          companyLineItems={companyLineItems}
                                          lineItems={lineItems}
                                          reFetchCompanyLineItems={() => companyLineItemsQuery.refetch()}
                                          onCompanyUpdated={() => {
                                              companyQuery.refetch();
                                          }}
                                          userCompanies={userCompanies}
                                          users={users}
                                          invoices={invoices}
                                          reFetchInvoices={() => invoicesQuery.refetch()}
                                          onUserCompanyUpdated={() => {
                                              usersQuery.refetch();
                                          }}
                                          transactions={transactions}
                                          onPaymentCreated={() => {
                                              transactionsQuery.refetch();
                                              paymentRequestsQuery.refetch();
                                          }}
                                          payments={payments}
                                          reFetchPayments={() => paymentsQuery.refetch()}
                                          roleGroups={theRoleGroups}
                                          paymentRequests={paymentRequests}
                                          onPaymentRequestCreated={() => {
                                              paymentRequestsQuery.refetch();
                                          }}
                                          onPaymentRequestUpdated={() => {
                                              paymentRequestsQuery.refetch();
                                          }}
                                          hedgeFunds={hedgeFunds}
                                          reFetchPaymentRequests={() => {
                                              paymentRequestsQuery.refetch();
                                          }}
                                          reFetchTransactions={() => {
                                              transactionsQuery.refetch();
                                          }}
                                          reFetchUserCompanies={() => {
                                              usersQuery.refetch();
                                          }}
                                      />
                                  )
                              );
                          },
                      },
                  ]
                : []),
            ...((isCPAUser ||
                isSuperUser ||
                isTaxAdvocateUser ||
                isTaxAdvocateAffiliate ||
                isTaxAdvocateAffiliateAgent) &&
            company
                ? [
                      {
                          name: "Application",
                          id: "application",
                          icon: BriefcaseIcon,
                          content: () => {
                              return owner ? (
                                  window.location.hostname === "login.ercrepair.com" ? (
                                      <Phase1
                                          user={owner}
                                          companyId={companyId}
                                          onChange={() => {
                                              progressQuery.refetch();
                                          }}
                                      />
                                  ) : (
                                      <Application
                                          user={owner}
                                          companyId={companyId}
                                          onChange={() => {
                                              progressQuery.refetch();
                                          }}
                                      />
                                  )
                              ) : null;
                          },
                      },
                      {
                          name: "CPA Management",
                          id: "cpa",
                          icon: CurrencyDollarIcon,
                          content: () => {
                              const cpaUser = users.find((u) => u.roleGroups?.find((a) => a.id === 8));
                              return (
                                  <>
                                      <CPA
                                          companyId={companyId}
                                          user={theUser}
                                          cpaUser={cpaUser}
                                          taxAdvocateUser={taxAdvocate}
                                          notesSection={
                                              <Notes
                                                  user={user}
                                                  companyOwnerId={ownerUserId}
                                                  notes={filteredNotes.filter(
                                                      (note) =>
                                                          [taxAdvocate?.id, cpaUser?.id, null].includes(
                                                              note?.interaction_from,
                                                          ) ||
                                                          [taxAdvocate?.id, cpaUser?.id, null].includes(
                                                              note?.interaction_to,
                                                          ),
                                                  )}
                                                  company={company}
                                                  onNoteCreated={() => {
                                                      interactionsQuery.refetch();
                                                  }}
                                                  onNoteUpdated={() => {
                                                      interactionsQuery.refetch();
                                                  }}
                                                  users={users}
                                              />
                                          }
                                      />
                                  </>
                              );
                          },
                      },
                  ]
                : []),
            {
                name: "Notes",
                id: "notes",
                icon: PencilSquareIcon,
                content: () => {
                    return (
                        ownerUserId && (
                            <Notes
                                user={user}
                                companyOwnerId={ownerUserId}
                                notes={filteredNotes}
                                company={company}
                                onNoteCreated={() => {
                                    interactionsQuery.refetch();
                                }}
                                onNoteUpdated={() => {
                                    interactionsQuery.refetch();
                                }}
                                users={users}
                            />
                        )
                    );
                },
            },
            {
                name: "Phone Calls",
                id: "phone",
                icon: PhoneIcon,
                content: () => {
                    return (
                        ownerUserId &&
                        company && (
                            <Calls
                                calls={calls}
                                companyOwnerId={ownerUserId}
                                userCompanies={userCompanies}
                                roles={theRoleGroups as any[]}
                                company={company}
                                onCallCreated={() => {
                                    interactionsQuery.refetch();
                                }}
                                users={users}
                            />
                        )
                    );
                },
            },
            {
                name: "Messages",
                id: "messages",
                icon: EnvelopeIcon,
                content: () => {
                    return (
                        company &&
                        ownerUserId && (
                            <Messages
                                company={company}
                                companyOwnerId={ownerUserId}
                                messages={messages}
                                onMessageCreated={() => {
                                    interactionsQuery.refetch();
                                }}
                                users={users}
                                userCompanies={userCompanies}
                                roles={theRoleGroups as any[]}
                            />
                        )
                    );
                },
            },
            {
                name: "Files",
                id: "files",
                icon: FilesIcon,
                content: () => {
                    return ownerUserId && company && <Files companyId={companyId} />;
                },
            },
        ],
        [
            isSuperUser,
            company,
            isCPAUser,
            isTaxAdvocateUser,
            applicationPercentageProgress,
            owner,
            affiliate,
            payMaster,
            data,
            accountant,
            taxAttorney,
            companyLocations,
            companyQuery,
            ownerQuery,
            usersQuery,
            companyLocationsQuery,
            companyId,
            progressQuery,
            theRoleGroups,
            userCompanies,
            users,
            darkMode,
            removeUserMutation,
            updateCommission,
            ownerUserId,
            progressQueryHistory,
            companyLineItems,
            lineItems,
            invoices,
            transactions,
            payments,
            paymentRequests,
            hedgeFunds,
            companyLineItemsQuery,
            invoicesQuery,
            transactionsQuery,
            paymentRequestsQuery,
            paymentsQuery,
            theUser,
            taxAdvocate,
            user,
            filteredNotes,
            interactionsQuery,
            calls,
            messages,
        ],
    );

    const activeTab = tab || navItems[0].id;
    const activeNavItem = useMemo(() => navItems.find((item) => item.id === activeTab), [activeTab, navItems]);

    const ActiveItemComp = activeNavItem?.content;
    const Body = ActiveItemComp?.() || null;

    const ready = !!company && !!ActiveItemComp;
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";

    return !ready ? (
        <Loader2 />
    ) : (
        <>
            <div className="flex flex-1 overflow-hidden bg-white shadow sm:rounded-lg h-full dark:bg-gray-800">
                <main className="flex flex-1 overflow-hidden">
                    <div className="flex lg:flex-1 flex-row lg:flex-col overflow-y-auto xl:overflow-hidden w-full">
                        <div className="lg:flex lg:flex-1 xl:overflow-hidden w-full">
                            {!isMobile && !isTablet ? (
                                <nav
                                    aria-label="Sections"
                                    className="min-h-screen w-60 flex-shrink-0 border-r border-blue-gray-200 dark:border-gray-900 bg-white xl:flex xl:flex-col dark:bg-gray-800"
                                >
                                    <div className="flex h-16 flex-shrink-0 items-center border-b border-gray-200 px-6 dark:border-gray-900">
                                        <p className="text-lg font-medium text-blue-gray-900 dark:text-gray-400">
                                            {company.name}
                                        </p>
                                    </div>
                                    <div className="min-h-0 flex-1 overflow-y-auto">
                                        {navItems.map((item) => {
                                            const isActive = activeNavItem && item.id === activeNavItem?.id;
                                            return (
                                                <button
                                                    key={item.name}
                                                    onClick={() => {
                                                        navigate(`/companies/${companyId}?tab=${item.id}`);
                                                    }}
                                                    className={classNames(
                                                        isActive
                                                            ? "bg-slate-500 bg-opacity-10 dark:bg-indigo-900 dark:bg-opacity-30"
                                                            : "hover:bg-blue-50 hover:bg-opacity-50 hover:dark:bg-blue-900 hover:dark:bg-opacity-30",
                                                        "flex p-4 border-b border-blue-gray-200 text-left w-full dark:border-gray-900",
                                                    )}
                                                    aria-current={isActive ? "page" : undefined}
                                                >
                                                    <item.icon
                                                        className="-mt-0.5 h-6 w-6 flex-shrink-0 text-indigo-600 dark:text-green-400"
                                                        aria-hidden="true"
                                                    />
                                                    <div className="ml-3 text-sm">
                                                        <p className="font-medium text-gray-900 dark:text-gray-300">
                                                            {item.name}
                                                        </p>
                                                    </div>
                                                </button>
                                            );
                                        })}
                                    </div>
                                </nav>
                            ) : (
                                <div className="p-4 flex justify-between w-full">
                                    <h1 className="font-semibold text-sm">{activeNavItem.name}</h1>
                                    <OptionsDropDown
                                        asEllipses
                                        options={navItems.map((item) => {
                                            const isActive = activeNavItem && item.id === activeNavItem?.id;
                                            return {
                                                label: item.name,
                                                action: () => navigate(`/companies/${companyId}?tab=${item.id}`),
                                            };
                                        })}
                                    />
                                </div>
                            )}
                            <div className="flex-1 xl:overflow-y-auto">
                                <div className="flex-1 py-5 px-0 sm:px-6 lg:px-0">{Body}</div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};
