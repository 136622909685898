/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { useContext, useEffect, useLayoutEffect } from "react";
import { Dialog, Switch } from "@headlessui/react";
import { Bars3CenterLeftIcon } from "@heroicons/react/24/outline";
import { NavLink, useLocation } from "react-router-dom";
import UserContext from "../../services/user-context";
import authService from "../../services/auth.service";
import PrintPre from "../../layout/print-pre";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePromise } from "../../jason-proof-of-concept/shared/hooks";
import Loader2 from "../utilities/Loader2";
import menuService from "../../services/menu.service";
import { Navigation } from "../../typings/api/navigations";
import { UserIcon } from "@heroicons/react/24/solid";
import LogoBox from "../../layout/LogoBox";
import MessagesOverlay from "../dashboard/messages-overlay";
import { useRecoilState } from "recoil";
import { getAlerts } from "../../alerts/actions/get-alerts";
import userAtom from "../../atoms/userAtom";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { getAuthTokenNoThrow } from "../../services/auth-header";

type NavigationType = {
    name: string;
    icon: string;
    href: string;
    current: boolean;
    requiredRoles?: string[];
};

const checker = (arr: string[], target: string[]) => target.every((v) => arr.includes(v));

const user = {
    name: "Tom Cook",
    email: "tom@example.com",
    imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [
    { name: "Dashboard", href: "#", current: true },
    { name: "Team", href: "#", current: false },
    { name: "Projects", href: "#", current: false },
    { name: "Calendar", href: "#", current: false },
    { name: "Reports", href: "#", current: false },
];
const userNavigation = [
    // { name: "Your Profile", href: "#" },
    {
        name: "Your Application",
        href: window.location.hostname === "login.ercrepair.com" ? "/processflow-wizard/28" : "/processflow-wizard/7",
    },
    { name: "Sign out", href: "/logout" },
];

const affiliateInTrainingNavigation = [{ name: "Sign out", href: "/logout" }];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

type DashboardTopProps = {
    className?: string;
    children?: React.ReactNode;
    isAffiliateInTraining?: boolean;

    darkMode: boolean;
    setDarkMode: (darkMode: boolean) => void;
    sideBarOpen?: boolean;
    setSideBarOpen?: (sidebarOpen: boolean) => void;
};

export default function DashboardTop(props: DashboardTopProps) {
    const [userObject, setUserObject] = useRecoilState<ClearERCUser>(userAtom);
    const { isAffiliateInTraining } = props;
    const [darkMode, setDarkMode] = useState(false);
    const [usePadding, setUsePadding] = useState(true);
    const [navigation, setNavigation] = useState<Navigation[]>([]);
    const [showMessages, setShowMessages] = useState(false);
    const authToken = getAuthTokenNoThrow() || "no-auth";
    // lg:border-t lg:border-b lg:border-gray-200 dark:border-gray-900

    const cache = useQueryClient();
    // const [navigation, setNavigation] = useState<NavigationType[]>(initialNavigation.filter(n => n.requiredRoles === null || checker(authService.roles, n.requiredRoles ?? [])));
    // const [navigation, setNavigation] = useState<NavigationType[]>(initialNavigation);
    function setWrapperEnabledDarkMode(enabled: boolean) {
        // $('body').toggleClass('dark-theme');

        // alert(enabled);

        props.setDarkMode(enabled);
        if (enabled) {
            document.body.classList.add("dark-theme");
            document.body.classList.add("dark");
            localStorage.setItem("theme", "dark");
        } else {
            document.body.classList.remove("dark-theme");
            document.body.classList.remove("dark");
            localStorage.setItem("theme", "light");
        }
        // window.location.reload();
    }

    const menusQuery = useQuery(["menus"], async () => {
        return await menuService.getAllVisibleOrdered();
        // if (result) {
        // return result.data
        // }
    });
    const appAlertsQuery = usePromise(async () => {
        if (!userObject.id) {
            throw new Error("User not found");
        }
        const appAlerts = await getAlerts({
            authToken,
            filters: {
                where: {
                    userId: userObject.id,
                    channel: "app",
                },
            },
        });
        return appAlerts;
    }, []);

    useLayoutEffect(() => {
        if (location.pathname === "/dashboard") {
            setUsePadding(false);
        } else {
            setUsePadding(true);
        }

        setNavigation((current) =>
            current.map((item) => {
                if (item.href === location.pathname) {
                    item.current = 1;
                } else {
                    item.current = 0;
                }
                return item;
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
    useEffect(() => {
        if (menusQuery.isSuccess) {
            const roles = authService.roles;
            // let newNav = initialNavigation.filter(n => n.requiredRoles === null || checker(authService.roles.map(r => r.roleCode ?? ""), n.requiredRoles ?? []))
            const newNav = (menusQuery.data ?? [])
                .map((item) => {
                    if (
                        (window.location.hostname === "login.ercrepair.com" ||
                            window.location.hostname === "localhost") &&
                        item.id === 16
                    ) {
                        item.href = "/processflow-wizard/28";
                        console.log({ item });
                    }
                    if (item.href === location.pathname) {
                        item.current = 1;
                    } else {
                        item.current = 0;
                    }
                    return item;
                })
                .filter(
                    (n) =>
                        n.requiredRoles === null ||
                        checker(
                            authService.roles.map((r) => r?.roleCode ?? ""),
                            // JSON.parse(n.requiredRoles).roleGroups ?? [],
                            (n.requiredRoles as any).roleGroups ?? [],
                        ),
                );
            setNavigation(newNav);

            // setNavigation()
            // });
            // .catch((err) => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menusQuery.isSuccess, menusQuery.data, location.pathname]);

    // const [sidebarOpen, setSidebarOpen] = useState(false);

    if (menusQuery.isLoading) {
        return <Loader2 />;
    }

    return (
        <Disclosure
            as="nav"
            className="border-b border-indigo-300 border-opacity-25 bg-gradient-to-br from-indigo-700 to-indigo-800 lg:border-none"
        >
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                            <div className="flex items-center px-2 lg:px-0">
                                <div className="flex-shrink-0">
                                    <LogoBox />
                                </div>
                                {!isAffiliateInTraining && (
                                    <div className="hidden lg:ml-10 lg:block">
                                        <div className="flex space-x-4">
                                            {navigation.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className={classNames(
                                                        item.current
                                                            ? "bg-indigo-700 text-white"
                                                            : "text-gray-200 hover:bg-indigo-500 hover:bg-opacity-75",
                                                        "rounded-md py-2 px-3 text-sm font-medium",
                                                    )}
                                                    aria-current={item.current ? "page" : undefined}
                                                >
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>

                            {!isAffiliateInTraining && (
                                <>
                                    <div className="flex lg:hidden">{/* Mobile menu button */}</div>
                                    <div className="hidden lg:ml-4 lg:flex lg:items-center">
                                        <Switch.Group
                                            as="li"
                                            className="flex-shrink-0 py-0 px-5 flex items-center justify-between mr-4 pb-0"
                                        >
                                            <div className="flex flex-col">
                                                <Switch.Label
                                                    as="p"
                                                    className="text-sm font-medium text-gray-100 dark:text-gray-300"
                                                    passive
                                                >
                                                    {!props.darkMode ? "Light Mode" : "Dark Mode"}
                                                </Switch.Label>
                                            </div>
                                            <Switch
                                                checked={props.darkMode}
                                                onChange={setWrapperEnabledDarkMode}
                                                className={classNames(
                                                    props.darkMode ? "bg-blue-800" : "bg-gray-200",
                                                    "ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500",
                                                )}
                                            >
                                                <span className="sr-only">Use setting</span>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        props.darkMode ? "translate-x-5" : "translate-x-0",
                                                        "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
                                                    )}
                                                />
                                            </Switch>
                                        </Switch.Group>
                                    </div>
                                </>
                            )}
                            <div className="flex lg:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="bg-opacity-100!important inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 z-50">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6 z-50" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            {/* Hamburger Menu */}
                            <Disclosure.Panel className="lg:hidden z-10      ">
                                <div className="px-2 pt-2 pb-3 space-y-1     ">
                                    {/* <div className="pt-4 pb-3 border-t border-indigo-700"> */}
                                    {/* <div className="px-5 flex items-center"> */}
                                    {/* Full Screen Grey Overlay for menu to be modal */}
                                    <div
                                        className="fixed inset-0 bg-gray-600 bg-opacity-90 transition-opacity"
                                        aria-hidden="true"
                                    ></div>
                                    <div className="flex-shrink-0">
                                        <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                                    </div>
                                    <div className="ml-3">
                                        <div className="text-base font-medium text-white">{user.name}</div>
                                        <div className="text-sm font-medium text-indigo-300">{user.email}</div>
                                    </div>
                                    {/* Overlay to make menu modal */}
                                    <div className="absolute top-0 right-0 pt-2 pb-3 border-t border-indigo-700">
                                        <div className="px-5 flex items-center">
                                            {/* <div className="flex-shrink-0"> */}
                                            {/* <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" /> */}
                                            {/* </div> */}
                                            <div className="ml-3">
                                                {/* <div className="text-base font-medium text-white">{user.name}</div> */}

                                                <div className="mt-3 px-2 space-y-1 z-20">
                                                    {navigation.map((item) => (
                                                        <Disclosure.Button
                                                            key={item.name}
                                                            as="a"
                                                            href={item.href}
                                                            className={classNames(
                                                                item.current
                                                                    ? "bg-indigo-700 text-white"
                                                                    : "text-white hover:bg-indigo-500 hover:bg-opacity-75",
                                                                "block rounded-md py-2 px-3 text-base font-medium",
                                                            )}
                                                            aria-current={item.current ? "page" : undefined}
                                                        >
                                                            {item.name}
                                                        </Disclosure.Button>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Disclosure.Panel>

                            <div className="hidden lg:ml-4 lg:block">
                                <div className="flex items-center">
                                    {!isAffiliateInTraining && (
                                        <button
                                            type="button"
                                            className="flex-shrink-0 rounded-full bg-indigo-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                                            onClick={() => setShowMessages(true)}
                                        >
                                            <span className="sr-only">View notifications</span>
                                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    )}

                                    {/* Profile dropdown */}
                                    <Menu as="div" className="relative ml-3 flex-shrink-0">
                                        <div>
                                            <Menu.Button className="flex rounded-full bg-indigo-600 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                                                <span className="sr-only">Open user menu</span>
                                                <UserIcon className="fas fa-user pt-1 h-8 w-8 rounded-full bg-slate-900" />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-800  py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {(isAffiliateInTraining
                                                    ? affiliateInTrainingNavigation
                                                    : userNavigation
                                                ).map((item) => (
                                                    <Menu.Item key={item.name}>
                                                        {({ active }) => (
                                                            <a
                                                                href={item.href}
                                                                className={classNames(
                                                                    active ? "bg-gray-200" : "",
                                                                    "block py-2 px-4 text-sm text-gray-700 dark:text-gray-400",
                                                                )}
                                                            >
                                                                {item.name}
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MessagesOverlay show={showMessages} setShow={setShowMessages} alertsQuery={appAlertsQuery} />
                </>
            )}
        </Disclosure>
    );
}
