import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company } from "../../domain/company";
import { SelectField } from "../../../layout/form/select-field";
import { useMemo, useState } from "react";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { sortUsers } from "../../../sections/utilities/sortUsers";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSetupCompany } from "../../hooks/use-setup-company";
import { RoleGroup } from "../../../role-groups/domain/role-group";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import { CheckboxField } from "../../../layout/form/checkbox-field";
import { PasswordField } from "../../../layout/form/password-field";
import authService from "../../../services/auth.service";
import { roleAssignments } from "../../../services/role-group.service";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import companyService from "../../../services/company.service";
import { CurrencyField } from "../../../layout/form/currency-field";
import { NumberField } from "../../../layout/form/number-field";
import utmLinkService from "../../../services/utm-link.service";
import PrintPre from "../../../layout/print-pre";
import SelectNeoGenSearchable from "../../../layout/select-neogen-searchable";
import utmLogService from "../../../services/utm-log.service";
import { UTMLog } from "../../../typings/api/utm-logs";

const schema = z.object({
    name: z.string().min(1),
    roleId: z.number().nullish(),
    // address: z
    //     .object({
    //         line1: z.string().nullish(),
    //         line2: z.string().nullish(),
    //         city: z.string().nullish(),
    //         state: z.string().nullish(),
    //         country: z.string().nullish(),
    //     })
    //     .nullish(),
    affiliateId: z.string().uuid().nullish(),
    ownerFirstName: z.string().min(1),
    ownerLastName: z.string().min(1),
    ownerEmail: z.string().email().min(1),
    ownerPhone: z.string().min(1),
    preSetPassword: z.boolean().optional(),
    password: z.string().min(8).optional(),
    confirmPassword: z.string().min(8).optional(),
    isUnderAudit: z.string().optional(),
    ercAmount: z.number().optional(),
    commissionPercentage: z.number().optional(),
    // w2_2020: z.coerce.number().nullish(),
    // w2_2021: z.coerce.number().nullish(),
});

type Data = z.infer<typeof schema>;

export const NewCompanyModal = ({
    onClose,
    onCompanyCreated,
    affiliates,
    roleId,
    roleGroups,
}: {
    onClose: () => any;
    onCompanyCreated: ({ company, owner }: { company: Company; owner: User }) => void;
    affiliates?: User[];
    roleId?: number;
    roleGroups?: RoleGroup[];
}) => {
    const [theUser] = useRecoilState(userAtom);
    const isSuperUser = theUser?.roleGroups?.find((r: any) => r.id === 4);
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const [utmCode, setUtmCode] = useState<string>("");
    const form = useForm({ schema, defaultValues: { affiliateId: isSuperUser ? undefined : theUser.id, roleId } });

    const setupCompanyMutation = useSetupCompany();

    const values = form.watch();

    const formRoleId = values.roleId;

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            if (data.preSetPassword && data.password !== data.confirmPassword) {
                throw new Error("Passwords do not match");
            }
            try {
                const { company, owner } = await setupCompanyMutation.mutateAsync({
                    authToken,
                    data: {
                        company: {
                            name: data.name,
                            // address: [data.address?.line1, data.address?.line2].join(", "),
                            // city: data.address?.city,
                            // state: data.address?.state,
                            // country: data.address?.country,
                        },
                        owner: {
                            firstName: data.ownerFirstName,
                            lastName: data.ownerLastName,
                            email: data.ownerEmail,
                            phone: data.ownerPhone,
                            userStatus: "invited",
                            affiliateUserId: data.affiliateId,
                        },
                        ownerRoleId: roleId || data.roleId || undefined,
                        ownerPassword: data.preSetPassword ? data.password : undefined,
                        isUnderAudit: parseInt(data.isUnderAudit || "0"),
                        ercAmount: data.ercAmount,
                        commissionPercentage: data.commissionPercentage || 15,
                        // ...(data.w2_2020 || data.w2_2021
                        //     ? {
                        //           w2Employees: {
                        //               "2020": data.w2_2020 || 0,
                        //               "2021": data.w2_2021 || 0,
                        //           },
                        //       }
                        //     : {}),
                    },
                });
                if (utmCode) {
                    // Create a new utm log entry
                    const logEntry: UTMLog = {
                        eventDatetime: new Date(),
                        utm: utmCode,
                        site:
                            window.location.hostname === "login.ercrepair.com" ||
                            window.location.hostname === "localhost"
                                ? "ercrepair"
                                : "clearerc",
                        userId: theUser.id || "",
                        ipAddress: "",
                        notes: "",
                        data: JSON.stringify({
                            name: data.name,
                            email: data.ownerEmail,
                            phone: data.ownerPhone,
                            companyId: company.id,
                        }),
                    };
                    await utmLogService.create(logEntry);
                }
                if (data.roleId === 40) {
                    // TaxPayerAdvocateClient
                    const pp = {
                        completedSteps: [],
                        entriesByField: {
                            "Have W2 employees ": { fieldName: "Have W2 employees", fieldValue: "1", isHidden: false },
                            "Average full time W2 Employees in 2020?": {
                                fieldName: "Average full time W2 Employees in 2020?",
                                fieldValue: "0",
                                isHidden: false,
                            },
                            "Average full time W2 Employees in 2021?": {
                                fieldName: "Average full time W2 Employees in 2021?",
                                fieldValue: "0",
                                isHidden: false,
                            },
                            "Company 1 name": {
                                fieldName: "Company 1 name",
                                fieldValue: data.name,
                                isHidden: false,
                            },
                            "Company name": {
                                fieldName: "Company name",
                                fieldValue: data.name,
                                isHidden: false,
                            },
                            "Owners First Name": {
                                fieldName: "Owners First Name",
                                fieldValue: data.ownerFirstName,
                                isHidden: false,
                            },
                            "Owners Last Name": {
                                fieldName: "Owners Last Name",
                                fieldValue: data.ownerLastName,
                                isHidden: false,
                            },
                            "Phone Number": { fieldName: "Phone Number", fieldValue: data.ownerPhone, isHidden: false },
                            "Email address": {
                                fieldName: "Email address",
                                fieldValue: data.ownerEmail,
                                isHidden: false,
                            },
                            "Total ERC Refund": { fieldName: "Total ERC Refund", fieldValue: "0", isHidden: false },
                            "Is the ERC claim under audit with IRS": {
                                fieldName: "Is the ERC claim under audit with IRS",
                                fieldValue: "0",
                                isHidden: false,
                            },
                        },
                    };
                }
                return { company, owner };
            } catch (e) {
                console.error(e);
                const response = await companyService.getFilteredWhere({ email: data.ownerEmail });
                if (response && response.data?.length > 0) {
                    alert("User with this email already exists");
                    throw "User with this email already exists";
                } else {
                    throw e;
                }
            }
        },
    });

    const handleSubmit = async (data: Data) => {
        try {
            const { company, owner } = await submitMutation.mutateAsync(data);
            onCompanyCreated({ company, owner });
        } catch (e) {
            console.error(e);
        }
    };

    const affiliateOptions = useMemo(
        () =>
            sortUsers((affiliates || []) as ClearERCUser[]).map((affiliate) => ({
                value: affiliate.id || "",
                label: `${[affiliate.firstName, affiliate.lastName].join(" ")} (${affiliate.email})`,
            })),
        [affiliates],
    );

    const utmLinksQuery = useQuery(["utmLinks"], async () => {
        let response;
        if (isSuperUser) {
            response = await utmLinkService.getFilteredWhere({
                and: [{ name: { neq: "" } }, { name: { neq: null } }],
            });
        } else {
            response = await utmLinkService.getFilteredWhere({
                and: [{ name: { neq: "" } }, { name: { neq: null } }, { owner: theUser.id }],
            });
        }

        if (response) {
            return response.data;
        }
    });

    const usersRoleGroups = (authService.getCurrentUser()?.user?.roleGroups || []) as any[];
    const roleGroupsIds = usersRoleGroups.reduce<number[]>((acc, roleGroup: any) => {
        return [...acc, ...(roleAssignments?.[roleGroup.id] || [])];
    }, []);
    const finalRoleGroups = (roleGroups || []).filter((rg) => roleGroupsIds.includes(rg.id || 9999));

    const options = finalRoleGroups.map((role) => ({ label: role.name || "", value: role.id as number }));

    if (utmLinksQuery.isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <ModalDialog
                show
                title={formRoleId === 6 ? "New Client" : formRoleId === 7 ? "New Affiliate" : "New Company"}
                close={onClose}
                showOk={false}
                showCancel={false}
                size="sm"
            >
                <Form onSubmit={form.handleSubmit(handleSubmit)} error={setupCompanyMutation.error as any}>
                    <div className="flex flex-row gap-10">
                        <div className="flex-1">
                            {options.length > 0 && !roleId && (
                                <>
                                    <SelectField label="Role" {...form.getFieldProps("roleId")} options={options} />
                                </>
                            )}

                            <SelectNeoGenSearchable
                                label="UTM Code"
                                options={
                                    utmLinksQuery.data?.map((utmLink) => ({
                                        name: utmLink.name + " " + utmLink.campaign || "",
                                        id: utmLink.id || "",
                                    })) || []
                                }
                                onChange={(e) => {
                                    setUtmCode(e.toString());
                                }}
                                value={utmCode}
                            />
                            <h3 className="mt-5">Details</h3>
                            <TextField
                                label="Company name"
                                {...form.getFieldProps("name")}
                                placeholder="ACME Corp"
                                isRequired
                            />
                            {/* <h3>Company Address</h3> */}
                            {/* <div className="flex flex-row gap-5 flex-1">
                                <div className="flex-1">
                                    <TextField label="Line 1" {...form.getFieldProps("address.line1")} />
                                </div>
                                <div className="flex-1">
                                    <TextField label="Line 2" {...form.getFieldProps("address.line2")} />
                                </div>
                            </div>
                            <div className="flex flex-row gap-5">
                                <div className="flex-1">
                                    <TextField label="City" {...form.getFieldProps("address.city")} />
                                </div>
                                <div className="flex-1">
                                    <TextField label="State" {...form.getFieldProps("address.state")} />
                                </div>
                            </div>
                            <div className="flex flex-row gap-5">
                                <div className="flex-1">
                                    <TextField label="Country" {...form.getFieldProps("address.country")} />
                                </div>
                            </div> */}
                            {/* <h3>Owner details</h3> */}
                            <div className="flex flex-row gap-5">
                                <div className="flex-1">
                                    <TextField
                                        isRequired
                                        label="First name"
                                        {...form.getFieldProps("ownerFirstName")}
                                        placeholder="John"
                                    />
                                </div>
                                <div className="flex-1">
                                    <TextField
                                        isRequired
                                        label="Last name"
                                        {...form.getFieldProps("ownerLastName")}
                                        placeholder="Doe"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row gap-5">
                                <div className="flex-1">
                                    <TextField
                                        label="Phone number"
                                        {...form.getFieldProps("ownerPhone")}
                                        placeholder="+44 123 456 789"
                                    />
                                </div>
                                <div className="flex-1">
                                    <TextField
                                        isRequired
                                        label="Email address"
                                        {...form.getFieldProps("ownerEmail")}
                                        placeholder="john@example.com"
                                    />
                                </div>
                            </div>
                            {roleId === 6 && (
                                <div className="flex flex-row align-bottom gap-5">
                                    <div className="flex-1">
                                        <CurrencyField
                                            label="Total amount filed for ERC"
                                            {...form.getFieldProps("ercAmount")}
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <SelectField
                                            label="ERC claim under IRS audit?"
                                            {...form.getFieldProps("isUnderAudit")}
                                            options={[
                                                { label: "Yes", value: "1" },
                                                { label: "No", value: "0" },
                                            ]}
                                        />
                                    </div>
                                </div>
                            )}
                            {roleId === 7 && (
                                <NumberField
                                    label="Override commission % (default 15%)"
                                    {...form.getFieldProps("commissionPercentage")}
                                    defaultValue={15}
                                />
                            )}
                            <CheckboxField label="Pre-set password" {...form.getFieldProps("preSetPassword")} />
                            {values.preSetPassword && (
                                <div className="flex flex-row gap-5">
                                    <div className="flex-1">
                                        <PasswordField label="Password" {...form.getFieldProps("password")} />
                                    </div>
                                    <div className="flex-1">
                                        <PasswordField
                                            label="Confirm password"
                                            {...form.getFieldProps("confirmPassword")}
                                        />
                                    </div>
                                </div>
                            )}
                            {isSuperUser && affiliateOptions.length > 0 && (
                                <SelectField
                                    label="Affiliate"
                                    {...form.getFieldProps("affiliateId")}
                                    options={affiliateOptions}
                                />
                            )}
                        </div>
                    </div>
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={setupCompanyMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={setupCompanyMutation.isLoading}>
                            Create {formRoleId === 6 ? "Client" : formRoleId === 7 ? "Affiliate" : "Company"}
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
