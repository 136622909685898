import { bytesToSize, formatEmail, formatPhonenumber } from "../sections/utilities/formatters";
import { NamedColorspace } from "@textea/json-viewer";
import { JsonViewer } from "@textea/json-viewer";
import { useQuery } from "@tanstack/react-query";
import Loader2 from "../sections/utilities/Loader2";
import usersService from "../services/users.service";
import companyService from "../services/company.service";
import processflowGroupService from "../services/processflow-group.service";
import businessRuleTypeService from "../services/business-rule-type.service";
import processflowService from "../services/processflow.service";
import { Fragment, useCallback, useState, useEffect } from "react";
import roleGroupService from "../services/role-group.service";
import defaultSourcesService from "../services/default-sources.service";
import ButtonNeoGen, { ButtonNeoGenTypes } from "./button-neogen";
import interactionTypesService from "../services/interaction-types.service";
import OptionsDropDown from "./options-dropdown";
import SelectNeoGen from "./select-neogen";
import { Entry } from "./select-neogen";
import PrintPre from "./print-pre";
import { stringify } from "querystring";
import SelectNeoGenSearchable from "./select-neogen-searchable";
import Filter from "./filter";
import { ErcUser } from "../typings/api/erc-user";
import businessRuleGroupService from "../services/business-rule-group.service";
import Badge from "./badge";

const camel2title = (camelCase: string) =>
    camelCase
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim();

function compare(a: any, b: any) {
    if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
        return -1;
    }
    if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
        return 1;
    }
    return 0;
}

export const solarized: NamedColorspace = {
    scheme: "Solarized Light",
    author: "Ethan Schoonover (modified by aramisgithub)",
    base00: "fdf6e3",
    base01: "eee8d5",
    base02: "93a1a1",
    base03: "839496",
    base04: "657b83",
    base05: "586e75",
    base06: "073642",
    base07: "002b36",
    base08: "dc322f",
    base09: "cb4b16",
    base0A: "b58900",
    base0B: "859900",
    base0C: "2aa198",
    base0D: "268bd2",
    base0E: "6c71c4",
    base0F: "d33682",
};

export const summerfruitDark: NamedColorspace = {
    scheme: "Summerfruit Dark",
    author: "Christopher Corley (http://christop.club/)",
    base00: "151515",
    base01: "202020",
    base02: "303030",
    base03: "505050",
    base04: "B0B0B0",
    base05: "D0D0D0",
    base06: "E0E0E0",
    base07: "FFFFFF",
    base08: "FF0086",
    base09: "FD8900",
    base0A: "ABA800",
    base0B: "00C918",
    base0C: "1FAAAA",
    base0D: "3777E6",
    base0E: "AD00A1",
    base0F: "CC6633",
};

export default function TableNeogen(props: TableNeogenProps) {
    const getDarkMode = useCallback(() => {
        if (localStorage.getItem("theme") !== null) {
            if (localStorage.getItem("theme") === "dark") {
                document.body.classList.add("dark-theme");
                document.body.classList.add("dark");
                return true;
            } else {
                document.body.classList.remove("dark-theme");
                document.body.classList.remove("dark");
                return false;
            }
        } else {
            return false;
        }
    }, []);
    const roleGroupQuery = useQuery(
        ["roleGroups"],
        async () => {
            const response = await roleGroupService.getAll();
            if (response) {
                return response.data;
            }
            return [];
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "RoleGroup")?.length ?? 0) > 0,
        },
    );
    const messageTypesQuery = useQuery(
        ["messageTypes"],
        async () => {
            const response = await interactionTypesService.getAll();
            if (response) {
                return response.data;
            }
            return [];
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "MessageType")?.length ?? 0) > 0,
        },
    );
    const defaultSourcesQuery = useQuery(
        ["defaultSources"],
        async () => {
            const response = await defaultSourcesService.getAll();
            if (response) {
                return response.data;
            }
            return [];
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "DefaultSource")?.length ?? 0) > 0,
        },
    );
    const groupQuery = useQuery(
        ["groups"],
        async () => {
            const response = await processflowGroupService.getAll();
            if (response) {
                return response.data;
            }
            return [];
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "ProcessFlowGroup")?.length ?? 0) > 0,
        },
    );
    const processflowQuery = useQuery(
        ["processFlow"],
        async () => {
            const response = await processflowService.getAll();
            if (response) {
                return response.data;
            }
            return [];
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "Processflow")?.length ?? 0) > 0,
        },
    );
    const usersQuery = useQuery(
        ["users"],
        async () => {
            const response = await usersService.getAll();
            if (response) {
                return response.data;
            }
            return [];
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "User")?.length ?? 0) > 0,
        },
    );

    const ruleTypeQuery = useQuery(
        ["business-rule-types2"],
        async () => {
            const response = await businessRuleTypeService.getAll();
            if (response) {
                return response.data;
            } else {
                return [];
            }
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "RuleType")?.length ?? 0) > 0,
        },
    );
    const ruleGroupQuery = useQuery(
        ["business-rule-groups"],
        async () => {
            const response = await businessRuleGroupService.getAll();
            if (response) {
                return response.data;
            } else {
                return [];
            }
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "RuleGroup")?.length ?? 0) > 0,
        },
    );

    const companyQuery = useQuery(
        ["companies"],
        async () => {
            const response = await companyService.getAll();
            if (response) {
                return response.data.sort(compare);
            }
        },
        {
            enabled: (props.formatters?.filter((e) => e?.type === "Company")?.length ?? 0) > 0,
        },
    );

    const [entries, setEntries] = useState<any[]>([]);
    const [px, setPx] = useState(3);
    const [py, setPy] = useState(3.5);
    const [pytd, setPytd] = useState(4);

    const [headers, setHeaders] = useState<string[]>([]);
    useEffect(() => {
        if (props.compact) {
            setPx(1);
            setPy(0);
            setPytd(1);
        }
    }, [props.compact]);
    useEffect(() => {
        if (!props.entries) {
            return;
        } else {
            if (!props.entries[0]) {
                return;
            }
        }
        const entries = JSON.parse(JSON.stringify(props.entries));
        const newEntries = entries.map((entry: any) => {
            if (props.ignoreFields) {
                props.ignoreFields.forEach((i) => {
                    try {
                        delete entry[i];
                    } catch (x) {
                        console.error(x);
                    }
                });
            }
            if (entry) {
                Object.entries(entry).forEach((e2) => {
                    const fo = props.formatters?.find((f) => {
                        return f.field === e2[0];
                    });
                    if (fo) {
                        switch (fo.type) {
                            case "Date":
                                entry[e2[0]] = new Date(e2[1] as string).toLocaleDateString();
                                break;
                            case "DateNullable":
                                entry[e2[0]] = e2[1] ? new Date(e2[1] as string).toLocaleDateString() : "-";
                                break;
                            case "Time":
                                entry[e2[0]] = new Date(e2[1] as string).toLocaleTimeString();
                                break;
                            case "Currency":
                                entry[e2[0]] = Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                }).format(Number(e2[1]) ?? 0);
                                break;
                            case "CurrencyTimes100":
                                entry[e2[0]] = Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                }).format(Number(e2[1] ?? 0) / 100);
                                break;
                            case "CallStatus":
                                switch (e2[1]) {
                                    case "noanswer":
                                        entry[e2[0]] = (
                                            <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xxs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                No Answer
                                            </span>
                                        );
                                        break;
                                    case "no-answer":
                                        entry[e2[0]] = (
                                            <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xxs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                                                No Answer
                                            </span>
                                        );
                                        break;
                                    case "completed":
                                        entry[e2[0]] = (
                                            <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xxs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                Answered
                                            </span>
                                        );
                                        break;
                                    default:
                                        entry[e2[0]] = (
                                            <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xxs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                {status}
                                            </span>
                                        );
                                        break;
                                }
                                break;
                            case "Datetime":
                            case "DateTime":
                                if (!e2[1]) {
                                    // e[e2[0]] = "-";
                                } else {
                                    entry[e2[0]] = new Date(e2[1] as string).toLocaleString();
                                }
                                break;
                            case "Phone":
                                entry[e2[0]] = formatPhonenumber(e2[1] as string);
                                break;
                            case "PhoneNoSpace":
                                entry[e2[0]] = formatPhonenumber(e2[1] as string)?.replaceAll(" ", "");
                                break;
                            case "Select":
                                entry[e2[0]] = (
                                    <SelectNeoGen
                                        onChange={(id, value) => {
                                            console.log({ id, value, e2, fo, entry });
                                            if (fo.onChange) {
                                                fo.onChange(entry.id, id);
                                            }
                                        }}
                                        value={e2[1] as string | number | undefined}
                                        options={fo.options}
                                    />
                                );
                                break;
                            case "SelectSearch":
                                entry[e2[0]] = (
                                    <SelectNeoGenSearchable
                                        onChange={(id, value) => {
                                            console.log({ id, value, e2, fo, entry });
                                            if (fo.onChange) {
                                                fo.onChange(entry.id, id);
                                            }
                                        }}
                                        value={e2[1] as string | number | undefined}
                                        options={fo.options}
                                    />
                                );
                                break;
                            case "Color":
                                entry[e2[0]] = (
                                    <div
                                        className={"h-5 w-5"}
                                        style={{ backgroundColor: (e2[1] as string) ?? "#fff" }}
                                    />
                                );
                                break;
                            case "Boolean":
                                entry[e2[0]] = e2[1] ? (
                                    <span className="fas fa-circle-check text-green-500" />
                                ) : (
                                    <span className="fas fa-circle-xmark text-red-500" />
                                );
                                break;
                            case "DisplayItem":
                                // eslint-disable-next-line no-case-declarations
                                const obj: any = e2[1] as object;
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                entry[e2[0]] = obj[fo.itemToDisplay!];
                                break;
                            case "FileSize":
                                entry[e2[0]] = bytesToSize(Number(e2[1]));
                                break;
                            case "User":
                                entry[e2[0]] = usersQuery.data?.find((i) => i.id === e2[1])?.name;
                                break;
                            case "UserObject":
                                entry[e2[0]] = (e2[1] as ErcUser)?.name;
                                break;
                            case "Email":
                                entry[e2[0]] = entry[e2[0]] = formatEmail(e2[1] as string);
                                break;
                            case "Company":
                                entry[e2[0]] = companyQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "CompanyLink":
                                entry[e2[0]] = companyQuery.data?.find((i) => i.id === e2[1]) ? (
                                    <ButtonNeoGen onClick={() => (window.location.href = "/companies/" + e2[1])}>
                                        {companyQuery.data?.find((i) => i.id === e2[1])?.name ?? "-"}
                                    </ButtonNeoGen>
                                ) : (
                                    <>Company Not Found</>
                                );
                                break;
                            case "PayLink":
                                entry[e2[0]] =
                                    e2[1] === null ? (
                                        <></>
                                    ) : (
                                        <ButtonNeoGen
                                            icon="fal fa-credit-card"
                                            onClick={() => window.open(e2[1] as string, "_blank", "popup")}
                                        >
                                            Pay Now
                                        </ButtonNeoGen>
                                    );
                                break;
                            case "RuleType":
                                entry[e2[0]] = ruleTypeQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "RuleGroup":
                                entry[e2[0]] = (
                                    <>
                                        {(e2[1] as any[]).map((e3: any, idx: number) => (
                                            <div
                                                key={e3.id}
                                                className="text-sm my-1  dark:border-gray-600  dark:text-gray-400 inline-block mr-3"
                                            >
                                                <Badge
                                                    color={e3.isQualify === 1 ? "green" : "red"}
                                                    label={
                                                        ruleGroupQuery.data?.find((i) => i.id === e3.id)?.name ?? "-"
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </>
                                );
                                // entry[e2[0]] = ruleGroupQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "MessageType":
                                entry[e2[0]] = messageTypesQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "ProcessFlowGroup":
                                entry[e2[0]] = groupQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "RoleGroup":
                                entry[e2[0]] = roleGroupQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "DefaultSource":
                                entry[e2[0]] = defaultSourcesQuery.data?.find((i) => i.id === e2[1])?.name ?? "-";
                                break;
                            case "Processflow":
                                entry[e2[0]] = processflowQuery.data?.find((i) => i.id === e2[1])?.title ?? "-";
                                break;
                            case "CustomFunction":
                                entry[e2[0]] = fo.customFunction?.(e2[1]);
                                break;
                            case "CustomFunctionFullRow":
                                entry[e2[0]] = fo.customFunction?.(entry, e2);
                        }
                    }
                });
            }

            return entry;
        });
        setEntries(newEntries);
        if (props.headers) {
            setHeaders(props.headers);
        } else {
            if (newEntries[0]) {
                // console.error(newEntries);
                let keys = Object.keys(newEntries[0]);
                keys = keys.map((k) => {
                    return camel2title(k);
                });
                setHeaders(keys);
            }
        }
    }, [
        props.entries,
        props.formatters,
        props.headers,
        props.ignoreFields,
        usersQuery.data,
        groupQuery.data,
        companyQuery.data,
        ruleTypeQuery.data,
        processflowQuery.data,
        roleGroupQuery.data,
        defaultSourcesQuery.data,
        messageTypesQuery.data,
        ruleGroupQuery.data,
    ]);

    if (!props.entries || props.entries.length === 0) {
        return <></>;
    }

    // if (
    //     usersQuery.isLoading ||
    //     messageTypesQuery.isLoading ||
    //     companyQuery.isLoading ||
    //     groupQuery.isLoading ||
    //     ruleTypeQuery.isLoading ||
    //     processflowQuery.isLoading ||
    //     roleGroupQuery.isLoading ||
    //     defaultSourcesQuery.isLoading
    // ) {
    //     return <Loader2 />;
    // }

    function formatTableCell(key: string, value: unknown) {
        const typeOfField = props.formatters?.find((formatter) => formatter.field === key)?.type;
        if (typeOfField === "iconShort") {
            return (
                <>
                    <span className={"fal " + value + " mr-3"}></span>({value})
                </>
            );
        } else if (
            typeOfField === "JSON" ||
            (typeof value === "object" &&
                value !== null &&
                typeOfField !== "Boolean" &&
                typeOfField !== "Color" &&
                typeOfField !== "CustomFunctionFullRow" &&
                typeOfField !== "Select" &&
                typeOfField !== "CallStatus" &&
                typeOfField !== "CompanyLink" &&
                typeOfField !== "RuleGroup" &&
                typeOfField !== "PayLink" &&
                typeOfField !== "SelectSearch")
        ) {
            return (
                <JsonViewer
                    value={value as object}
                    quotesOnKeys={false}
                    theme={getDarkMode() ? solarized : summerfruitDark}
                    displayDataTypes={false}
                    displayObjectSize={false}
                />
            );
        }
        return value as string;
    }
    return (
        <>
            <div
                className={
                    props.actions && props.actionsAreDropDown && !props.hideMargin
                        ? " pb-56 md:overflow-hidden rounded-xl "
                        : ""
                }
            >
                <div
                    className={
                        "  rounded-t-xl  flex flex-col w-full shadow " +
                        " min-w-full  align-middle md:px-6 lg:px-0 dark:border-none dark:rounded-xl" +
                        " dark:border-slate-600/60 ring-1 ring-black ring-opacity-5 " +
                        (props.compact ? " rounded " : " rounded-xl ")
                    }
                >
                    <table
                        // style={border - collapse: separate !important;}
                        className={
                            "table-fixed border-collapse min-w-full divide-y divide-gray-300 dark:divide-gray-800 rounded-xl  "
                        }
                    >
                        <thead
                            className={
                                (props.compact ? " rounded  " : " rounded-xl  ") +
                                " dark:border-0  " +
                                (props.headerBg ?? " bg-gray-50  dark:bg-slate-800 ") +
                                " transition-colors duration-500 " +
                                (props.refreshing && "bg-red-50 dark:bg-gray-800 ")
                            }
                        >
                            <tr className=" rounded-xl ">
                                {headers.map((h, i) => {
                                    return (
                                        <Fragment key={i}>
                                            {h === "Id" ? (
                                                <></>
                                            ) : (
                                                <th
                                                    key={i}
                                                    scope="col"
                                                    className={
                                                        " " +
                                                        "dark:text-gray-400 tracking-wider  " +
                                                        (props.compact
                                                            ? " text-xs font-light "
                                                            : " text-sm font-semibold  ") +
                                                        (i === 1
                                                            ? " rounded-tl-xl py-" +
                                                              py +
                                                              " pl-4 pr-" +
                                                              px +
                                                              " text-left  text-gray-900 sm:pl-6"
                                                            : "px-" + px + " py-" + py + " text-left  text-gray-900") +
                                                        (i === headers.length - 1 + (props.actions ?? []).length
                                                            ? " rounded-tr-xl "
                                                            : " ")
                                                    }
                                                >
                                                    {h}
                                                </th>
                                            )}
                                        </Fragment>
                                    );
                                })}
                                {props.actions && !props.actionsAreDropDown && (
                                    <>
                                        {props.actions.map((a: any, idx: number) => (
                                            <th
                                                key={idx}
                                                className={
                                                    idx === (props.actions ?? []).length - 1 ? " rounded-tr-xl " : ""
                                                }
                                            ></th>
                                        ))}
                                    </>
                                )}
                                {props.actions && props.actionsAreDropDown && (
                                    <th className=" dark:text-gray-400 tracking-wider   text-sm font-semibold  px-3 py-3.5 text-left  text-gray-900">
                                        Options
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white  dark:bg-slate-700 dark:divide-gray-900 rounded-xl">
                            {entries.map((entry, idx) => (
                                <tr key={idx} className="">
                                    {Object.entries(entry)?.map(([key, value], idx2) => {
                                        return (
                                            <Fragment key={key}>
                                                {key === "id" ? (
                                                    <></>
                                                ) : (
                                                    <td
                                                        key={key}
                                                        className={
                                                            "" +
                                                            "dark:text-gray-400 break-all " +
                                                            (props.compact
                                                                ? " text-xs font-light "
                                                                : " text-sm font-medium ") +
                                                            " " +
                                                            (props.customColumnClasses?.find((x) => x.field === key)
                                                                ?.classNames ?? "  ") +
                                                            " " +
                                                            (idx2 === 0
                                                                ? "py-" + pytd + " pl-4 pr-3  text-gray-500 sm:pl-6"
                                                                : "px-" + px + " py-" + pytd + "  text-gray-500")
                                                        }
                                                    >
                                                        {formatTableCell(key, value)}
                                                    </td>
                                                )}
                                            </Fragment>
                                        );
                                    })}
                                    {props.actions && props.actionsAreDropDown && (
                                        <td
                                            key={idx}
                                            className={
                                                "text-right dark:text-gray-400 dark:bg-slate-700 w-4 " +
                                                "px-" +
                                                px +
                                                " py-" +
                                                pytd +
                                                " text-sm text-gray-500 dark:bg-gray-800"
                                            }
                                        >
                                            {/* {props.actions.map((a: any, idx: number) => (
                                                    <th key={idx}></th>
                                                ))} */}
                                            <OptionsDropDown
                                                options={props.actions.map((action) => {
                                                    if (!action.icon) {
                                                        action.icon = "";
                                                    }
                                                    return {
                                                        ...action,
                                                        action: () => {
                                                            action.onClick(entry.id);
                                                        },
                                                        disabled: action.disabled?.(entry.id) ?? false,
                                                    };
                                                })}
                                            />
                                        </td>
                                    )}
                                    {props.actions && !props.actionsAreDropDown && (
                                        <>
                                            {props.actions.map((a: TableAction, idx: number) => (
                                                <td
                                                    key={idx}
                                                    className={
                                                        "text-right dark:text-gray-300 dark:bg-slate-700 " +
                                                        "px-" +
                                                        px +
                                                        " py-" +
                                                        pytd +
                                                        " text-sm text-gray-500 dark:bg-gray-800 "
                                                    }
                                                >
                                                    <ButtonNeoGen
                                                        type={a.type}
                                                        className={
                                                            a.disabled?.(entry.id) ?? false
                                                                ? "bg-gray-400"
                                                                : a.className ??
                                                                  (a.type
                                                                      ? ""
                                                                      : "bg-indigo-600 text-white dark:bg-indigo-800 dark:text-gray-300 ") +
                                                                      " hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 " +
                                                                      " focus:ring-indigo-500 "
                                                        }
                                                        onClick={() => a.onClick(entry.id)}
                                                        disabled={a.disabled ? a.disabled(entry.id) : false}
                                                        size={a.size || props.compact ? "xxs" : "sm"}
                                                    >
                                                        <span className="whitespace-nowrap">
                                                            {a.icon && (
                                                                <span className={a.icon + " mr-2 whitespace-nowrap"} />
                                                            )}
                                                            {a.label}
                                                            {a.labelActive && a.labelActive(entry.id)}
                                                        </span>
                                                    </ButtonNeoGen>
                                                </td>
                                            ))}
                                        </>
                                    )}
                                    {/* <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                          {entry.name}
                                      </td>
                                      <td className="px-3 py-4 text-sm text-gray-500">{entry.title}</td>
                                      <td className="px-3 py-4 text-sm text-gray-500">{entry.email}</td>
                                      <td className="px-3 py-4 text-sm text-gray-500">{entry.role}</td>
                                      <td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                          <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                              Edit<span className="sr-only">, {entry.name}</span>
                                          </a>
                                      </td> */}
                                </tr>
                                // /* // { key === "Stuff" && ( */
                                //     // entry.Stuff && (
                                //     // <tr>
                                //     //     <td colSpan={1000} className=" ">
                                //     //         {/* <JsonViewer
                                //     //             value={entry.Stuff.props.children}
                                //     //             quotesOnKeys={false}
                                //     //             // collapseStringsAfterLength={10}
                                //     //             editable={false}
                                //     //             theme={getDarkMode() ? "dark" : "light"}
                                //     //             displayDataTypes={false}
                                //     //             displayObjectSize={false}
                                //     //         /> */}
                                //     //         {/* <PrintPre>{entry.Stuff.props.children}</PrintPre> */}
                                //     //     </td>
                                //     // </tr>
                                //     // )

                                // </React.Fragment>
                            ))}
                        </tbody>
                        <tfoot className="bg-white dark:bg-slate-700 first-letter:rounded-b-xl overflow-hidden">
                            <tr className="rounded-b-xl overflow-hidden">
                                <td colSpan={1000} className=" rounded-b-xl overflow-hidden">
                                    <div className=" dark:bg-slate-700 rounded-xl border-none border-t-2 border-to divide-none">
                                        <div className="px-4 py-3 sm:px-6 border-xl"></div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    );
}

export type TableAction = {
    label?: string;
    labelActive?: (id: any) => string;
    onClick: (id: any) => void;
    className?: string;
    icon?: string;
    size?: "xxs" | "xs" | "sm" | "md" | "lg" | "xl";
    type?: ButtonNeoGenTypes;
    disabled?: (id: any) => boolean;
};

export type Formatter = {
    field: string;
    type:
        | "Boolean"
        | "CallStatus"
        | "Color"
        | "Company"
        | "CompanyLink"
        | "Currency"
        | "CurrencyTimes100"
        | "CustomFunction"
        | "CustomFunctionFullRow"
        | "Date"
        | "DateNullable"
        | "DateTime"
        | "Datetime"
        | "DefaultSource"
        | "DisplayItem"
        | "Email"
        | "FileSize"
        | "iconShort"
        | "JSON"
        | "MessageType"
        | "PayLink"
        | "Phone"
        | "PhoneNoSpace"
        | "ProcessFlowGroup"
        | "Processflow"
        | "RoleGroup"
        | "RuleType"
        | "RuleGroup"
        | "Select"
        | "SelectSearch"
        | "Time"
        | "User"
        | "UserObject";
    itemToDisplay?: string;
    expanded?: boolean;
    options?: Entry[];
    onChange?: (id: any, value: any) => void;
    customFunction?: (item: any, item2?: any) => any;
};

export type ColumnClass = {
    field: string;
    classNames: string;
};

export type TableNeogenProps = {
    headerBg?: string;
    entries: any[];
    headers?: string[];
    ignoreFields?: string[];
    formatters?: Formatter[];
    breaks?: any[];
    customColumnClasses?: ColumnClass[];
    actions?: TableAction[];
    actionsAreDropDown?: boolean;
    refreshing?: boolean;
    compact?: boolean;
    expandJSON?: boolean;
    hideMargin?: boolean;
};
