import { useEffect, useState } from "react";
import { Currency } from "../../billing/components";
import { getEstimatedPayoutFromData } from "../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { Company } from "../domain/company";
import { EditGrossFeeModal } from "./modals/edit-gross-fee-modal";
import { EditAffiliateFeeModal } from "./modals/edit-affiliate-fee-modal";
import { UserCompany } from "../../user-companies/domain/user-company";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { EditUserCommissionModal } from "./modals/edit-user-commission-modal";
import { orderBy, startCase, sumBy } from "lodash";
import { classNames } from "../../billing/utils";
import OptionsDropDown from "../../layout/options-dropdown";
import { CreatePaymentModal } from "./modals/create-payment-modal";
import { Transaction } from "../../transactions/domain/transaction";
import { Payment } from "../../payments/domain/payment";
import { RoleGroup } from "../../role-groups/domain/role-group";
import { CreatePaymentRequestModal } from "./modals/create-payment-request-modal";
import { PaymentRequest, PaymentRequestStatus } from "../../payment-requests/domain/payment-request";
import SwalNeogenFire from "../../layout/swal-neogen";
import { getDarkMode } from "../../sections/admin/magic-links/magic-links";
import { useUpdatePaymentRequest } from "../../payment-requests/hooks/use-update-payment-request";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { AlertMessage } from "../../layout/error-section";
import ButtonNeoGen from "../../layout/button-neogen";
import { GeneratePayoutsModal } from "./modals/generate-payouts-modal";
import { CompanyLineItem } from "../../company-line-items/domain/company-line-item";
import { NewLineItemModal } from "../../company-line-items/components/new-line-item-modal";
import { LineItemsType } from "../../services/line-items.service";
import { RemoveLineItemModal } from "../../company-line-items/components/remove-line-item-modal";
import { NewInvoiceModal } from "./modals/new-invoice-modal";
import { Invoice } from "../../invoices/domain/invoice";
import { DateDisplay } from "../../layout/date-display";
import { EditLineItemModal } from "../../company-line-items/components/edit-line-item-modal";
import { NewInvoiceFlowModal } from "./new-invoice-flow-modal";
import { Section, Stat } from "../../affiliate-billing/affiliate-deals-page";
import { InvoiceModal } from "./modals/invoice-modal";

export const Billing = ({
    applicationData,
    company,
    onCompanyUpdated,
    userCompanies,
    invoices,
    reFetchInvoices,
    users,
    onUserCompanyUpdated,
    transactions,
    onPaymentCreated,
    onPaymentRequestCreated,
    onPaymentRequestUpdated,
    roleGroups,
    paymentRequests,
    hedgeFunds,
    reFetchPaymentRequests,
    reFetchTransactions,
    reFetchUserCompanies,
    reFetchPayments,
    companyLineItems,
    reFetchCompanyLineItems,
    lineItems,
    payments,
}: {
    applicationData: Record<string, any>;
    company: Company;
    onCompanyUpdated?: (updatedCompany: Company) => void;
    userCompanies: UserCompany[];
    users: User[];
    onUserCompanyUpdated?: (updatedCompany: UserCompany) => void;
    transactions: Transaction[];
    invoices?: Invoice[];
    reFetchInvoices?: () => void;
    onPaymentCreated?: (payment: Payment) => void;
    onPaymentRequestCreated?: (paymentRequest?: PaymentRequest) => void;
    onPaymentRequestUpdated?: (paymentRequest?: PaymentRequest) => void;
    roleGroups: RoleGroup[];
    paymentRequests: PaymentRequest[];
    hedgeFunds?: Company[];
    reFetchPaymentRequests?: () => void;
    reFetchTransactions?: () => void;
    reFetchUserCompanies?: () => void;
    reFetchCompanyLineItems?: () => void;
    reFetchPayments?: () => void;
    companyLineItems: CompanyLineItem[];
    lineItems: LineItemsType[];
    payments?: Payment[];
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const [showEditGrossFeeModal, setShowEditGrossFeeModal] = useState(false);
    const [showEditAffiliateFeeModal, setShowEditAffiliateFeeModal] = useState(false);
    const [showEditCommissionModalForUserId, setShowEditCommissionModalForUserId] = useState<string | undefined>();
    const [activeTab, setActiveTab] = useState("Overview");
    const [showCapturePaymentModal, setShowCapturePaymentModal] = useState<Partial<Payment> | undefined>();
    const [showNewPaymentRequestModal, setShowNewPaymentRequestModalWithValues] = useState<
        (Partial<PaymentRequest> & { maxAmount?: number }) | undefined
    >();
    const [showPayoutsModal, setShowPayoutsModal] = useState(false);
    const [showNewLineItemModal, setShowNewLineItemModal] = useState(false);
    const [showNewInvoiceModal, setShowNewInvoiceModal] = useState(false);
    const [showNewInvoiceFlowModal, setShowNewInvoiceFlowModal] = useState(false);
    const [showRemoveLineItemModalForCliId, setShowRemoveLineItemModal] = useState<number | undefined>();
    const [showEditLineItemModalForCliId, setShowEditCompanyLineItemModal] = useState<number | undefined>();
    const [invoiceIdToShow, setInvoiceToShow] = useState<number | undefined>();

    useEffect(() => {
        if (reFetchInvoices) {
            reFetchInvoices();
        }
        if (reFetchPayments) {
            reFetchPayments();
        }
    }, []);

    const estimatedAmount = getEstimatedPayoutFromData({ data: applicationData });
    const irsPayout = company.finalRefundAmount || 0;
    const grossFeeAmount = company.totalFeeAmount || 0;
    const clearErcFeePercentage =
        company.totalFeeType === "percentage"
            ? Number.parseFloat((company.totalFeePercent || 0).toFixed(2))
            : ((grossFeeAmount / irsPayout) * 100).toFixed(2);
    const clearErcFeeAmount =
        company.totalFeeType === "amount" ? grossFeeAmount : ((company.totalFeePercent || 0) / 100) * irsPayout;

    const allUsers = [...(users || [])];

    const combinedLineItems = companyLineItems.map((cli) => {
        const lineItem = lineItems.find((li) => li.id === cli.lineItemId);

        const amountDue = lineItem?.price || 0;
        const amountPaid = company
            ? sumBy(
                  transactions.filter(
                      (t) =>
                          (t.toCompanyId === company.id || t.fromCompanyId === company.id) &&
                          t.lineItemId === cli.lineItemId,
                  ),
                  (t) => (t.toCompanyId === company.id ? -t.amount : t.amount),
              )
            : 0;
        const amountPending = company
            ? sumBy(
                  paymentRequests.filter(
                      (pr) =>
                          (pr.toCompanyId === company.id || pr.fromCompanyId === company.id) &&
                          pr.lineItemId === cli.lineItemId &&
                          pr.status === PaymentRequestStatus.Pending,
                  ),
                  (pr) => (pr.toCompanyId === company.id ? -pr.amount : pr.amount),
              )
            : 0;
        const balance = amountDue - amountPaid - amountPending;

        return {
            cli,
            lineItem,
            amountDue,
            amountPaid,
            amountPending,
            balance,
        };
    });

    const members = [
        ...((userCompanies || [])
            .map((userCompany) => {
                const user = (users || []).find((u) => u.id === userCompany.ercUserId);
                const amountDue = userCompany.totalFeeAmount || 0;
                const amountPaid = user
                    ? sumBy(
                          transactions.filter((t) => t.toUserId === user.id || t.fromUserId === user.id),
                          (t) => (t.toUserId === user.id ? t.amount : -t.amount),
                      )
                    : 0;
                const amountPending = user
                    ? sumBy(
                          paymentRequests.filter(
                              (pr) =>
                                  (pr.toUserId === user.id || pr.fromUserId === user.id) &&
                                  pr.status === PaymentRequestStatus.Pending,
                          ),
                          (pr) => (pr.toUserId === user.id ? pr.amount : -pr.amount),
                      )
                    : 0;
                const balance = amountDue - amountPaid - amountPending;
                return {
                    userCompany,
                    user,
                    amountDue,
                    amountPending,
                    amountPaid,
                    balance,
                };
            })
            .filter((m) => !!m.user && !!m.userCompany) as {
            user: User;
            userCompany: UserCompany;
            amountDue: number;
            amountPending: number;
            amountPaid: number;
            balance: number;
        }[]),
    ];

    const companyPayments = payments || [];

    const paymentRequestsToClearErc = paymentRequests.filter((pr) => pr.toClearErc === true || pr.to === "ClearERC");
    const paymentRequestsToOthers = paymentRequests.filter(
        (pr) => pr.toClearErc === false || pr.fromClearErc === true || pr.toUserId,
    );

    const memberToEdit = members.find((m) => m.user.id === showEditCommissionModalForUserId);

    const irsDiff = estimatedAmount - (company.finalRefundAmount || 0);

    const feeTransactions = transactions.filter((t) => t.type === "fee");
    const feeAmountPaid = sumBy(
        feeTransactions.filter(
            (t) => (t.fromCompanyId === company.id && t.toClearErc) || (t.toCompanyId === company.id && t.fromClearErc),
        ),
        (t) => (t.fromCompanyId === company.id ? t.amount : -t.amount),
    );

    const feePaymentRequests = paymentRequests.filter((pr) => pr.type === "fee");
    const feeAmountPending = sumBy(
        feePaymentRequests.filter(
            (pr) =>
                ((pr.fromCompanyId === company.id && pr.toClearErc) ||
                    (pr.toCompanyId === company.id && pr.fromClearErc)) &&
                pr.status === PaymentRequestStatus.Pending,
        ),
        (pr) => (pr.fromCompanyId === company.id ? pr.amount : -pr.amount),
    );
    const feeBalance = -clearErcFeeAmount + feeAmountPaid + feeAmountPending;

    const updatePaymentRequestMutation = useUpdatePaymentRequest();

    const tabs = [{ name: "Overview" }, { name: "Payments" }];

    const filteredTransactions =
        activeTab === "Transactions to ClearErc"
            ? transactions.filter((t) => t.toClearErc === true)
            : transactions.filter((t) => t.toClearErc === false || t.fromClearErc === true || t.toUserId);

    const totalCommissionPercentage = sumBy(members.map((m) => m.userCompany.commissionPercentage || 0));

    const companyLineItemToUpdate = companyLineItems.find((cli) => cli.id === showEditLineItemModalForCliId);

    const totalIssued = sumBy(
        (invoices || []).filter((i) => i.status !== "draft"),
        (i) => i.amount || 0,
    );
    const totalPaid = sumBy(invoices, (i) => i.amountPaid || 0);

    const invoiceToView = (invoiceIdToShow && invoices && invoices.find((i) => i.id === invoiceIdToShow)) || null;

    return (
        <div className="p-4">
            {showEditGrossFeeModal && (
                <EditGrossFeeModal
                    applicationData={applicationData}
                    company={company}
                    onClose={() => {
                        setShowEditGrossFeeModal(false);
                    }}
                    onCompanyUpdated={(updatedCompany) => {
                        setShowEditGrossFeeModal(false);
                        if (onCompanyUpdated) {
                            onCompanyUpdated(updatedCompany);
                        }
                        if (reFetchPaymentRequests) {
                            reFetchPaymentRequests();
                        }
                        if (reFetchTransactions) {
                            reFetchTransactions();
                        }
                        if (reFetchUserCompanies) {
                            reFetchUserCompanies();
                        }
                    }}
                    hedgeFunds={hedgeFunds}
                />
            )}
            {invoiceToView && <InvoiceModal invoice={invoiceToView} onClose={() => setInvoiceToShow(undefined)} />}
            {showEditAffiliateFeeModal && (
                <EditAffiliateFeeModal
                    applicationData={applicationData}
                    company={company}
                    irsPayout={irsPayout}
                    clearErcGrossFee={clearErcFeeAmount}
                    onClose={() => {
                        setShowEditAffiliateFeeModal(false);
                    }}
                    onCompanyUpdated={(updatedCompany) => {
                        setShowEditAffiliateFeeModal(false);
                        if (onCompanyUpdated) {
                            onCompanyUpdated(updatedCompany);
                        }
                    }}
                />
            )}
            {showEditCommissionModalForUserId && memberToEdit && (
                <EditUserCommissionModal
                    clearErcFee={clearErcFeeAmount}
                    company={company}
                    dealValue={irsPayout}
                    user={memberToEdit.user}
                    userCompany={memberToEdit.userCompany}
                    onClose={() => {
                        setShowEditCommissionModalForUserId(undefined);
                    }}
                    onUpdated={(updatedUserCompany) => {
                        setShowEditCommissionModalForUserId(undefined);
                        if (onUserCompanyUpdated) {
                            onUserCompanyUpdated(updatedUserCompany);
                        }
                    }}
                />
            )}
            {showNewPaymentRequestModal && (
                <CreatePaymentRequestModal
                    companyId={company.id}
                    defaultValues={showNewPaymentRequestModal}
                    fromUser={showNewPaymentRequestModal.fromUser || undefined}
                    fromCompany={showNewPaymentRequestModal.fromCompany || undefined}
                    toUser={showNewPaymentRequestModal.toUser || undefined}
                    toCompany={showNewPaymentRequestModal.toCompany || undefined}
                    onClose={() => {
                        setShowNewPaymentRequestModalWithValues(undefined);
                    }}
                    maxAmount={showNewPaymentRequestModal.maxAmount}
                    onCreate={(paymentRequest) => {
                        setShowNewPaymentRequestModalWithValues(undefined);
                        if (onPaymentRequestCreated) {
                            onPaymentRequestCreated(paymentRequest);
                        }
                    }}
                />
            )}
            {showCapturePaymentModal && (
                <CreatePaymentModal
                    companyId={company.id}
                    defaultValues={showCapturePaymentModal}
                    fromUser={showCapturePaymentModal.fromUser || undefined}
                    fromCompany={showCapturePaymentModal.fromCompany || undefined}
                    toUser={showCapturePaymentModal.toUser || undefined}
                    toCompany={showCapturePaymentModal.toCompany || undefined}
                    onClose={() => {
                        setShowCapturePaymentModal(undefined);
                    }}
                    onPaymentCaptured={(payment) => {
                        setShowCapturePaymentModal(undefined);
                        if (onPaymentCreated) {
                            onPaymentCreated(payment);
                        }
                    }}
                />
            )}
            {showPayoutsModal && (
                <GeneratePayoutsModal
                    company={company}
                    userCompanies={userCompanies}
                    paymentRequests={paymentRequests}
                    onClose={() => {
                        setShowPayoutsModal(false);
                    }}
                    onPayoutsGenerated={() => {
                        if (onPaymentRequestCreated) {
                            onPaymentRequestCreated();
                        }
                        setShowPayoutsModal(false);
                    }}
                />
            )}
            {showNewLineItemModal && (
                <NewLineItemModal
                    onClose={() => setShowNewLineItemModal(false)}
                    companyId={company.id}
                    lineItems={lineItems}
                    onCreate={async () => {
                        if (reFetchCompanyLineItems) {
                            await reFetchCompanyLineItems();
                        }
                        setShowNewLineItemModal(false);
                    }}
                />
            )}
            {showNewInvoiceFlowModal && company && lineItems && (
                <NewInvoiceFlowModal
                    lineItems={lineItems}
                    company={company}
                    onClose={() => {
                        setShowNewInvoiceFlowModal(false);
                        if (reFetchInvoices) {
                            reFetchInvoices();
                        }
                    }}
                />
            )}
            {showNewInvoiceModal && (
                <NewInvoiceModal
                    onClose={() => setShowNewInvoiceModal(false)}
                    companyId={company.id}
                    defaultValues={{
                        lineItems: companyLineItems.map((cli) => ({
                            lineItemId: cli.lineItemId,
                            qty: 1,
                            price: cli.amount || 0,
                        })),
                    }}
                    lineItems={lineItems}
                    onCreate={() => {
                        if (reFetchInvoices) {
                            reFetchInvoices();
                        }
                        setShowNewInvoiceModal(false);
                    }}
                />
            )}
            {showRemoveLineItemModalForCliId && (
                <RemoveLineItemModal
                    onClose={() => setShowRemoveLineItemModal(undefined)}
                    companyLineItemId={showRemoveLineItemModalForCliId}
                    onRemoved={async () => {
                        if (reFetchCompanyLineItems) {
                            await reFetchCompanyLineItems();
                        }
                        setShowRemoveLineItemModal(undefined);
                    }}
                />
            )}
            {showEditLineItemModalForCliId && companyLineItemToUpdate && (
                <EditLineItemModal
                    companyLineItem={companyLineItemToUpdate}
                    lineItems={lineItems}
                    onClose={() => setShowEditCompanyLineItemModal(undefined)}
                    onUpdate={() => {
                        if (reFetchCompanyLineItems) {
                            reFetchCompanyLineItems();
                        }
                        setShowEditCompanyLineItemModal(undefined);
                    }}
                />
            )}

            <div className="lg:block">
                <div className="border-b border-gray-200 dark:border-gray-900">
                    <nav className="flex space-x-8">
                        {tabs.map((tab) => (
                            <span
                                key={tab.name}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setActiveTab(tab.name);
                                }}
                                className={classNames(
                                    tab.name === activeTab
                                        ? "border-indigo-500 text-black dark:text-gray-200 dark:bg-indigo-800 dark:px-5 dark:border-gray-800 rounded-t-xl"
                                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:hover:text-green-500",
                                    "whitespace-nowrap py-3 px-1 border-b-2 font-medium text-sm",
                                )}
                            >
                                {tab.name}
                            </span>
                        ))}
                    </nav>
                </div>
            </div>
            {activeTab === "Overview" && (
                <>
                    <Section
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                        }}
                        className="w-full"
                    >
                        <Stat title="Total Invoiced" value={<Currency amount={totalIssued} />} />
                        <Stat title="Total Paid" value={<Currency amount={totalPaid} />} />
                    </Section>
                    {/* <div className="flex flex-row gap-10">
                        <div className="flex-1">
                            <Table>
                                <TBody>
                                    <Tr>
                                        <Td style={{ width: 200, color: "gray", verticalAlign: "top" }}>
                                            Estimated deal value
                                        </Td>
                                        <Td>
                                            <b>
                                                <Currency amount={estimatedAmount} />
                                            </b>
                                        </Td>
                                    </Tr>
                                </TBody>
                            </Table>
                        </div>
                        <div className="flex-1">
                            <Table>
                                <TBody>
                                    <Tr>
                                        <Td style={{ width: 200, color: "gray", verticalAlign: "top" }}>
                                            Final refund amount
                                        </Td>
                                        <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <b>
                                                <Currency amount={company.finalRefundAmount || 0} />
                                            </b>
                                            {estimatedAmount > 0 && (
                                                <>
                                                    <br />
                                                    <span style={{ color: "gray" }}>
                                                        <Currency amount={Math.abs(irsDiff)} /> (
                                                        {(Math.abs(irsDiff / estimatedAmount) * 100).toFixed(1)}%){" "}
                                                        {irsDiff > 0 ? "less" : "more"}
                                                    </span>
                                                </>
                                            )}
                                        </Td>
                                        <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <div className="flex flex-row flex-1 justify-end">
                                                <ButtonNeoGen
                                                    type="outline"
                                                    onClick={() => {
                                                        setShowEditGrossFeeModal(true);
                                                    }}
                                                >
                                                    Edit
                                                </ButtonNeoGen>
                                            </div>
                                        </Td>
                                    </Tr>
                                </TBody>
                            </Table>
                        </div>
                    </div>
                    <br /> */}
                    {/* <Table>
                        <THead>
                            <Tr>
                                <Th style={{ width: "24%" }}>Item</Th>
                                <Th style={{ width: "22%" }}>Due</Th>
                                <Th style={{ width: "13%" }}>Paid</Th>
                                <Th style={{ width: "13%" }}>Pending</Th>
                                <Th style={{ width: "13%" }}>Balance</Th>
                                <Th style={{ width: 90 }}></Th>
                            </Tr>
                        </THead>
                        <TBody>
                            <Tr>
                                <Td style={{ width: 200, verticalAlign: "top" }}>ClearERC gross fee</Td>
                                <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="flex flex-col">
                                        <div>
                                            <b>
                                                <Currency amount={clearErcFeeAmount} />
                                            </b>{" "}
                                            {clearErcFeePercentage && !isNaN(clearErcFeePercentage as any) && (
                                                <span>({clearErcFeePercentage}%)</span>
                                            )}
                                        </div>
                                        {company.totalFeeType && (
                                            <span style={{ color: "gray" }}>Configured as {company.totalFeeType}</span>
                                        )}
                                    </div>
                                </Td>
                                <Td>
                                    <Currency amount={feeAmountPaid} />
                                </Td>
                                <Td>
                                    <Currency amount={feeAmountPending} />
                                </Td>
                                <Td>
                                    <Currency amount={feeBalance} />
                                </Td>
                                <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <div className="flex flex-row flex-1 justify-end">
                                        <OptionsDropDown
                                            options={[
                                                {
                                                    label: "Edit fee",
                                                    onClick: () => {
                                                        setShowEditGrossFeeModal(true);
                                                    },
                                                },
                                                {
                                                    label: "Create payment request",
                                                    onClick: () => {
                                                        setShowNewPaymentRequestModalWithValues({
                                                            description: "Fee payment",
                                                            amount: feeBalance * -1,
                                                            fromCompanyId: company.id,
                                                            fromCompany: company,
                                                            toClearErc: true,
                                                            type: "fee",
                                                        });
                                                    },
                                                },
                                                {
                                                    label: "Create payment",
                                                    onClick: () => {
                                                        setShowCapturePaymentModal({
                                                            description: "Fee payment",
                                                            amount: feeBalance * -1,
                                                            fromCompanyId: company.id,
                                                            fromCompany: company,
                                                            toClearErc: true,
                                                            type: "fee",
                                                        });
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                </Td>
                            </Tr>
                        </TBody>
                    </Table> */}
                    {/* <div
                        style={{
                            border: "1px solid lightgray",
                            borderRadius: 10,
                            padding: 10,
                            marginTop: 30,
                            marginBottom: 30,
                        }}
                    >
                        <div className="flex flex-row justify-between algin-center p-4">
                            <div style={{ fontSize: 18 }}>Line Items</div>
                            <div>
                                <ButtonNeoGen
                                    onClick={() => {
                                        setShowNewLineItemModal(true);
                                    }}
                                >
                                    New Line Item
                                </ButtonNeoGen>
                            </div>
                        </div>
                        <Table>
                            <THead>
                                <Tr>
                                    <Th style={{ width: "24%" }}>Name</Th>
                                    <Th style={{ width: "22%" }}>Commissionable</Th>
                                    <Th style={{ width: "22%" }}>Price</Th>
                                    <Th style={{ width: "22%" }}>Notes</Th>
                                    <Th style={{ width: 90 }}></Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {combinedLineItems.length === 0 && (
                                    <Tr>
                                        <Td colSpan={6} style={{ textAlign: "center" }}>
                                            No line items
                                        </Td>
                                    </Tr>
                                )}
                                {combinedLineItems.length > 0 &&
                                    combinedLineItems.map(
                                        ({ cli, lineItem, amountDue, amountPaid, amountPending, balance }) => {
                                            return (
                                                <Tr key={cli.id}>
                                                    <Td>{lineItem?.name}</Td>
                                                    <Td>{lineItem?.isCommisionable ? "Yes" : "No"}</Td>
                                                    <Td>
                                                        <Currency amount={cli.amount || 0} />
                                                    </Td>
                                                    <Td>{cli.notes}</Td>
                                                    <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                        <div className="flex flex-row flex-1 justify-end">
                                                            <OptionsDropDown
                                                                options={[
                                                                    {
                                                                        label: "Edit Item",
                                                                        onClick: () =>
                                                                            setShowEditCompanyLineItemModal(cli.id),
                                                                    },
                                                                    {
                                                                        label: "Remove Item",
                                                                        onClick: () =>
                                                                            setShowRemoveLineItemModal(cli.id),
                                                                    },
                                                                ]}
                                                            />
                                                        </div>
                                                    </Td>
                                                </Tr>
                                            );
                                        },
                                    )}
                            </TBody>
                        </Table>
                    </div> */}
                    <div
                        style={{
                            border: "1px solid lightgray",
                            borderRadius: 10,
                            padding: 10,
                            marginTop: 30,
                            marginBottom: 30,
                        }}
                    >
                        <div className="flex flex-row justify-between algin-center p-4">
                            <div style={{ fontSize: 18 }}>Invoices</div>
                            <div className="flex flex-row gap-2">
                                <ButtonNeoGen
                                    onClick={() => {
                                        setShowNewInvoiceFlowModal(true);
                                    }}
                                >
                                    New Invoice Flow
                                </ButtonNeoGen>
                                <ButtonNeoGen
                                    onClick={() => {
                                        setShowNewInvoiceModal(true);
                                    }}
                                >
                                    New Invoice
                                </ButtonNeoGen>
                            </div>
                        </div>
                        <Table>
                            <THead>
                                <Tr>
                                    <Th style={{ width: "24%" }}>Number</Th>
                                    <Th style={{ width: "24%" }}>Date Issued</Th>
                                    <Th style={{ width: "24%" }}>Status</Th>
                                    <Th style={{ width: "22%" }}>Amount</Th>
                                    <Th style={{ width: "22%" }}>Paid</Th>
                                    <Th style={{ width: 90 }}></Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {invoices && invoices.length === 0 && (
                                    <Tr>
                                        <Td colSpan={6} style={{ textAlign: "center" }}>
                                            No Invoices
                                        </Td>
                                    </Tr>
                                )}
                                {invoices &&
                                    invoices.length > 0 &&
                                    orderBy(invoices, (i) => -(i.createdAt?.getTime() || 0)).map((invoice) => {
                                        return (
                                            <Tr key={invoice.id}>
                                                <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                    <div
                                                        onClick={() => setInvoiceToShow(invoice.id)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {invoice.invoiceNumber}
                                                    </div>
                                                </Td>
                                                <Td>{invoice.issuedAt && <DateDisplay date={invoice.issuedAt} />}</Td>
                                                <Td>
                                                    <div>
                                                        {invoice.status === "paid" && invoice.paidAt ? (
                                                            <div>
                                                                Paid at <DateDisplay date={invoice.paidAt} />
                                                            </div>
                                                        ) : (
                                                            <div>{invoice.status && startCase(invoice.status)}</div>
                                                        )}
                                                    </div>
                                                </Td>
                                                <Td>
                                                    <Currency amount={invoice.amount || 0} />
                                                </Td>
                                                <Td>
                                                    <Currency amount={invoice.amountPaid || 0} />
                                                </Td>
                                                <Td>
                                                    <div className="flex flex-row flex-1 justify-end">
                                                        <OptionsDropDown
                                                            options={[
                                                                ...(invoice.qbInvoiceId
                                                                    ? [
                                                                          {
                                                                              label: "Manage on QuickBooks",
                                                                              onClick: () => {
                                                                                  window.open(
                                                                                      `https://app.qbo.intuit.com/app/invoice?txnId=${invoice.qbInvoiceId}`,
                                                                                  );
                                                                              },
                                                                          },
                                                                      ]
                                                                    : []),
                                                            ]}
                                                        />
                                                    </div>
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                            </TBody>
                        </Table>
                    </div>
                    <div
                        style={{
                            border: "1px solid lightgray",
                            borderRadius: 10,
                            padding: 10,
                            marginTop: 30,
                            marginBottom: 30,
                        }}
                    >
                        <div className="flex flex-row justify-between algin-center p-4">
                            <div style={{ fontSize: 18 }}>Commission Payouts</div>
                            <div className="flex flex-row gap-2">
                                <ButtonNeoGen
                                    onClick={() => {
                                        setShowNewLineItemModal(true);
                                    }}
                                >
                                    Add User
                                </ButtonNeoGen>
                                <ButtonNeoGen
                                    onClick={() => {
                                        setShowPayoutsModal(true);
                                    }}
                                >
                                    Generate Payouts
                                </ButtonNeoGen>
                            </div>
                        </div>
                        <Table>
                            <THead>
                                <Tr>
                                    <Th style={{ width: "24%" }}></Th>
                                    <Th style={{ width: "22%" }}>Due</Th>
                                    <Th style={{ width: "13%" }}>Paid</Th>
                                    <Th style={{ width: "13%" }}>Pending</Th>
                                    <Th style={{ width: "13%" }}>Balance</Th>
                                    <Th style={{ width: 90 }}></Th>
                                </Tr>
                                <Tr>
                                    <Th>Total</Th>
                                    <Th>
                                        <Currency amount={sumBy(members.map((m) => m.amountDue || 0))} /> (
                                        {sumBy(members.map((m) => m.userCompany.commissionPercentage || 0))}%)
                                    </Th>
                                    <Th>
                                        <Currency amount={sumBy(members.map((m) => m.amountPaid || 0))} />
                                    </Th>
                                    <Th>
                                        <Currency amount={sumBy(members.map((m) => m.amountPending || 0))} />
                                    </Th>
                                    <Th>
                                        <Currency amount={sumBy(members.map((m) => m.balance || 0))} />
                                    </Th>
                                    <Th />
                                </Tr>
                            </THead>
                            <TBody>
                                {members.map(({ user, userCompany, amountDue, amountPending, amountPaid, balance }) => {
                                    const roleGroup = roleGroups.find((rg) => userCompany.roleGroupId === rg.id);
                                    return (
                                        <Tr key={userCompany.id}>
                                            <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                <div className="flex flex-col">
                                                    <div>
                                                        {user?.firstName} {user?.lastName}{" "}
                                                        <span style={{ color: "gray" }}>
                                                            ({roleGroup?.name || "Member"})
                                                        </span>
                                                    </div>
                                                    <div style={{ color: "gray" }}>{user.email}</div>
                                                </div>
                                            </Td>
                                            <Td>
                                                <Currency amount={amountDue} /> ({userCompany.totalFeePercent || 0}%)
                                            </Td>
                                            <Td>
                                                <Currency amount={amountPaid} />
                                            </Td>
                                            <Td>
                                                <Currency amount={amountPending} />
                                            </Td>
                                            <Td>
                                                <Currency amount={balance} />
                                            </Td>
                                            <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                <div className="flex flex-row flex-1 justify-end">
                                                    <OptionsDropDown
                                                        options={[
                                                            {
                                                                label: "Edit commission",
                                                                onClick: () =>
                                                                    setShowEditCommissionModalForUserId(user.id),
                                                            },
                                                            {
                                                                label: "Create payment request",
                                                                onClick: () => {
                                                                    setShowNewPaymentRequestModalWithValues({
                                                                        description: `${roleGroup?.name} commission payout`,
                                                                        fromClearErc: true,
                                                                        toUser: user,
                                                                        toUserId: user.id,
                                                                        amount: balance,
                                                                        maxAmount: balance,
                                                                    });
                                                                },
                                                            },
                                                            {
                                                                label: "Create payment",
                                                                onClick: () => {
                                                                    setShowCapturePaymentModal({
                                                                        description: `${roleGroup?.name} commission payout`,
                                                                        fromClearErc: true,
                                                                        toUser: user,
                                                                        toUserId: user.id,
                                                                        amount: balance,
                                                                    });
                                                                },
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </>
            )}
            {activeTab === "Payments" && (
                <div>
                    <Table>
                        <THead>
                            <Tr>
                                <Th>Description</Th>
                                <Th>Date</Th>
                                <Th>From</Th>
                                <Th>To</Th>
                                <Th>Amount</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {companyPayments.length === 0 && (
                                <Tr>
                                    <Td colSpan={6} style={{ textAlign: "center" }}>
                                        No payments
                                    </Td>
                                </Tr>
                            )}
                            {companyPayments.length > 0 &&
                                companyPayments.map((payment) => {
                                    return (
                                        <Tr key={payment.id}>
                                            <Td>{payment.description}</Td>
                                            <Td>
                                                <DateDisplay date={payment.date} />
                                            </Td>
                                            <Td>
                                                {payment.fromClearErc
                                                    ? "ERCRepair"
                                                    : payment.from ||
                                                      payment.fromCompany?.name ||
                                                      [payment.fromUser?.firstName, payment.fromUser?.lastName].join(
                                                          " ",
                                                      )}
                                            </Td>
                                            <Td>
                                                {payment.toClearErc
                                                    ? "ERCRepair"
                                                    : payment.to ||
                                                      payment.toCompany?.name ||
                                                      [payment.toUser?.firstName, payment.toUser?.lastName].join(" ")}
                                            </Td>
                                            <Td>
                                                <Currency amount={payment.amount} />
                                            </Td>
                                            <Td>
                                                <OptionsDropDown options={[]} />
                                            </Td>
                                        </Tr>
                                    );
                                })}
                        </TBody>
                    </Table>
                </div>
            )}
        </div>
    );
};
