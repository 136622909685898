import { z } from "zod";
import { User } from "../../../../jason-proof-of-concept/users/domain/user";
import { getAuthTokenNoThrow } from "../../../../services/auth-header";
import { useForm } from "../../../../hooks/useForm";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createUser } from "../../../../jason-proof-of-concept/users/actions/create-user";
import ModalDialog from "../../../../layout/modal-dialog";
import { Form } from "../../../../layout/form/form";
import ButtonNeoGen from "../../../../layout/button-neogen";
import { TextField } from "../../../../layout/form/text-field";
import { EmailField } from "../../../../layout/form/email-field";
import { PasswordField } from "../../../../layout/form/password-field";
import { getUsers } from "../../../../jason-proof-of-concept/users/actions/get-users";
import { useMemo } from "react";
import { SelectField } from "../../../../layout/form/select-field";
import roleGroupService, { roleAssignments, roleGroups } from "../../../../services/role-group.service";
import authService from "../../../../services/auth.service";
import { sortUsers } from "../../../utilities/sortUsers";
import { ClearERCUser } from "../../../../typings/api/clear-erc-user";

const schema = z.object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z.string().email(),
    phone: z.string().nullish(),
    roleId: z.number().nullish(),
    password: z.string().nullish(),
    passwordAgain: z.string().nullish(),
    companyName: z.string().nullish(),
    affiliateUserId: z.string().nullish(),
    accountManagerUserId: z.string().nullish(),
    cpaUserId: z.string().nullish(),
    taxAttorneyUserId: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const AddUserModal = ({
    onClose,
    onUserCreated,
}: {
    onClose: () => any;
    onUserCreated: (updatedUser: User) => void;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";

    const form = useForm({ schema });

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            const { companyName: name, passwordAgain, roleId, ...filteredData } = data;
            const createdUser = await createUser({
                authToken,
                data: {
                    ...filteredData,
                    name,
                    emailVerified: true,
                    isTempPassword: true,
                },
            });

            if (!roleId) return createdUser;

            const createdRoleGroup = await roleGroupService.postURL("role-group-users", {
                clearErcUserId: createdUser.id,
                roleGroupsId: roleId,
            });

            return createdUser;
        },
    });

    const handleSubmit = async (data: Data) => {
        const createdUser = await mutation.mutateAsync(data);
        onUserCreated(createdUser);
    };

    const affiliatesQuery = useQuery(["users", "affiliates"], async () => {
        const response = await getUsers({ authToken, role: "Affiliate" });
        return response || [];
    });
    const affiliates = useMemo(() => sortUsers((affiliatesQuery.data as ClearERCUser[]) || []), [affiliatesQuery.data]);

    const accountantsQuery = useQuery(["users", "accountant"], async () => {
        const response = await getUsers({ authToken, role: "Accountant" });
        return response || [];
    });
    const accountants = useMemo(
        () => sortUsers((accountantsQuery.data as ClearERCUser[]) || []),
        [accountantsQuery.data],
    );

    const docCollectorsQuery = useQuery(["users", "docCollector"], async () => {
        const response = await getUsers({ authToken });
        return response || [];
    });
    const docCollectors = useMemo(
        () => sortUsers((docCollectorsQuery.data as ClearERCUser[]) || []),
        [docCollectorsQuery.data],
    );

    const taxAttorneyQuery = useQuery(["users", "taxAttorney"], async () => {
        const response = await getUsers({ authToken });
        return response || [];
    });
    const taxAttorneys = useMemo(
        () => sortUsers((taxAttorneyQuery.data as ClearERCUser[]) || []),
        [taxAttorneyQuery.data],
    );

    const roleGroupsQuery = useQuery(["roleGroups"], async () => {
        const response = await roleGroupService.getAll();
        return response?.data || [];
    });

    const allRoleGroups = roleGroupsQuery.data || [];
    const usersRoleGroups = (authService.getCurrentUser()?.user?.roleGroups || []) as any[];
    const roleGroupsIds = usersRoleGroups.reduce<number[]>((acc, roleGroup: any) => {
        return [...acc, ...(roleAssignments?.[roleGroup.id] || [])];
    }, []);
    const finalRoleGroups = allRoleGroups.filter((rg) => roleGroupsIds.includes(rg.id || 9999));

    return (
        <ModalDialog show title={"Add user"} close={onClose} showOk={false} showCancel={false}>
            <Form onSubmit={form.handleSubmit(handleSubmit)} error={mutation.error as any}>
                <div className="grid grid-cols-1 gap-3">
                    <SelectField
                        label="Role"
                        {...form.getFieldProps("roleId")}
                        options={finalRoleGroups.map((role) => ({
                            label: role.name || "No role name",
                            value: role.id || "no-name",
                        }))}
                    />
                    <TextField label="First name" {...form.getFieldProps("firstName")} isRequired={true} />
                    <TextField label="Last name" {...form.getFieldProps("lastName")} isRequired={true} />
                    <EmailField autoComplete="username" label="Email Address" {...form.getFieldProps("email")} />
                    <TextField label="Phone" {...form.getFieldProps("phone")} />
                    <PasswordField autoComplete="new-password" label="Password" {...form.getFieldProps("password")} />
                    <PasswordField autoComplete="off" label="Password Again" {...form.getFieldProps("passwordAgain")} />
                </div>
                <ButtonNeoGen block type="submit" disabled={mutation.isLoading}>
                    Create user
                </ButtonNeoGen>
            </Form>
        </ModalDialog>
    );
};
